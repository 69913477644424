export default function Logo(): JSX.Element {
  return (
    <svg width="121" height="32" viewBox="0 0 121 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Vodafone</title>
      <g clipPath="url(#clip0_43_169)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5602 30.6244C23.6368 30.6244 30.1819 24.0766 30.1819 16C30.1819 7.92341 23.6368 1.37564 15.5602 1.37564C7.48363 1.37564 0.935852 7.92341 0.935852 16C0.935852 24.0766 7.48363 30.6244 15.5602 30.6244ZM7.45945 15.2316C7.47826 20.7396 11.6589 24.1682 15.6757 24.1545C20.6356 24.1384 23.5695 20.0651 23.5588 16.3466C23.5453 12.6307 21.5249 9.94388 17.0728 8.8584C17.0621 8.8127 17.0541 8.71327 17.0541 8.61929C17.046 5.81155 19.1632 3.33432 21.8232 2.78351C21.5733 2.69753 21.1622 2.66534 20.7699 2.66534C17.7285 2.67609 14.3726 3.97921 11.9223 6.00507C9.41277 8.08466 7.446 11.591 7.45945 15.2316Z"
          fill="#0D1117"
        />
        <path
          d="M39.6261 22.0561L35.1552 11.3867H38.0758L40.8647 18.4718L43.5676 11.3867H46.3565L42.0173 22.0561H39.6261Z"
          fill="#0D1117"
        />
        <path
          d="M54.1376 16.6664C54.1376 16.223 54.0865 15.8012 53.9817 15.4009C53.8796 15.0005 53.7238 14.6566 53.5169 14.3691C53.31 14.079 53.0601 13.8506 52.7646 13.6813C52.469 13.5093 52.1224 13.4234 51.7248 13.4234C51.3271 13.4234 50.9779 13.5094 50.685 13.6813C50.3894 13.8505 50.1369 14.079 49.9327 14.3691C49.7258 14.6566 49.57 15.0005 49.4679 15.4009C49.3631 15.8012 49.312 16.223 49.312 16.6664C49.312 17.1258 49.3631 17.553 49.4679 17.9453C49.57 18.3376 49.7204 18.6815 49.9193 18.977C50.1181 19.2726 50.3706 19.5063 50.6743 19.6783C50.9752 19.8475 51.3271 19.9335 51.7248 19.9335C52.1224 19.9335 52.469 19.8475 52.7646 19.6783C53.0601 19.5063 53.31 19.2726 53.5169 18.977C53.7238 18.6815 53.8796 18.3376 53.9817 17.9453C54.0865 17.553 54.1376 17.1258 54.1376 16.6664ZM56.8378 16.6781C56.8378 17.3739 56.7303 18.0564 56.5181 18.7281C56.3031 19.3998 55.9861 19.9989 55.5643 20.5309C55.1451 21.0629 54.6131 21.4901 53.971 21.8153C53.3288 22.1404 52.5792 22.3016 51.7248 22.3016C50.8677 22.3016 50.1181 22.1403 49.4786 21.8153C48.8365 21.4902 48.3045 21.0629 47.8827 20.531C47.4635 19.999 47.1465 19.3998 46.9315 18.7281C46.7193 18.0564 46.6118 17.3739 46.6118 16.6781C46.6118 15.9849 46.7193 15.3078 46.9315 14.6414C47.1465 13.9778 47.4635 13.384 47.8827 12.8601C48.3045 12.3362 48.8365 11.917 49.4786 11.6C50.1181 11.2829 50.8677 11.1217 51.7248 11.1217C52.5792 11.1217 53.3261 11.283 53.9602 11.6C54.5943 11.917 55.1263 12.3362 55.5535 12.8601C55.9834 13.384 56.3031 13.9778 56.5181 14.6414C56.7303 15.3078 56.8378 15.9849 56.8378 16.6781Z"
          fill="#0D1117"
        />
        <path
          d="M65.0837 13.735C64.5544 13.5281 64.0627 13.4233 63.6059 13.4233C63.1384 13.4233 62.7327 13.5148 62.3969 13.692C62.0583 13.8692 61.7789 14.1084 61.5586 14.412C61.3382 14.7157 61.1743 15.0569 61.0642 15.4357C60.954 15.8119 60.8976 16.2015 60.8976 16.6018C60.8976 17.0451 60.9459 17.4696 61.0427 17.878C61.1367 18.2838 61.2845 18.6411 61.4833 18.9447C61.6795 19.2483 61.9293 19.4928 62.2303 19.6782C62.5312 19.8609 62.8885 19.955 63.2996 19.955C63.6382 19.955 63.9821 19.861 64.334 19.6782C64.686 19.4928 64.9359 19.2725 65.0837 19.0226L65.0837 13.735ZM65.304 22.056L65.1938 21.2393C64.944 21.5187 64.6135 21.7659 64.1997 21.9781C63.7886 22.1931 63.3023 22.3006 62.7434 22.3006C62.01 22.3006 61.3597 22.1448 60.8036 21.8357C60.242 21.5268 59.7718 21.113 59.3903 20.5971C59.0088 20.0813 58.7159 19.4821 58.5171 18.8023C58.3209 18.1252 58.2215 17.4159 58.2215 16.6771C58.2215 15.9839 58.3236 15.3041 58.5278 14.6404C58.7347 13.9768 59.0464 13.383 59.4655 12.8591C59.8874 12.3352 60.4113 11.916 61.0454 11.599C61.6768 11.2819 62.421 11.1207 63.2727 11.1207C63.8316 11.1207 64.4361 11.2254 65.0837 11.4297V7.29199H67.8269V22.056H65.304Z"
          fill="#0D1117"
        />
        <path
          d="M75.5542 17.0532C75.2586 17.0688 74.9523 17.0963 74.6407 17.1312C74.329 17.1688 74.0334 17.2172 73.7513 17.2763C73.1549 17.3944 72.7438 17.596 72.5127 17.8835C72.2843 18.1737 72.1688 18.4854 72.1688 18.8266C72.1688 19.1785 72.2978 19.4634 72.5584 19.6783C72.819 19.8906 73.1844 19.998 73.66 19.998C74.0173 19.998 74.3639 19.9255 74.6971 19.7777C75.0303 19.6299 75.3177 19.4445 75.5542 19.2242V17.0532ZM75.7745 22.048L75.667 21.25C75.3688 21.5778 74.9927 21.8358 74.5332 22.0212C74.0738 22.2066 73.5713 22.3006 73.0232 22.3006C72.5638 22.3006 72.1204 22.2226 71.7013 22.0668C71.2768 21.9138 70.9033 21.6907 70.5782 21.4032C70.2531 21.1157 69.9898 20.7637 69.791 20.3419C69.5895 19.9201 69.4901 19.4445 69.4901 18.9125C69.4901 18.3832 69.5921 17.913 69.8017 17.5073C70.0086 17.1016 70.288 16.7604 70.6454 16.4783C71 16.1988 71.4084 15.9705 71.8679 15.7931C72.3246 15.6159 72.8083 15.4814 73.3107 15.3928C73.6654 15.3202 74.055 15.2664 74.4768 15.2288C74.8986 15.1912 75.2506 15.159 75.5327 15.1294V14.9736C75.5327 14.3825 75.3822 13.9633 75.0759 13.7108C74.7723 13.4609 74.3532 13.3346 73.8212 13.3346C73.5095 13.3346 73.2113 13.3722 72.9292 13.4475C72.6471 13.52 72.3972 13.6115 72.1742 13.7216C71.9512 13.8345 71.755 13.9446 71.5831 14.0547C71.4138 14.1649 71.2768 14.2643 71.172 14.353L70.2639 12.4292C70.3821 12.3245 70.5621 12.2008 70.8066 12.0531C71.0511 11.9053 71.3439 11.7602 71.6932 11.6205C72.0398 11.4807 72.4294 11.3625 72.8647 11.2658C73.3 11.1691 73.7701 11.1207 74.2699 11.1207C74.861 11.1207 75.401 11.1987 75.8874 11.3545C76.3737 11.5103 76.7955 11.7522 77.1502 12.0853C77.5021 12.4158 77.7789 12.8349 77.9804 13.3454C78.1792 13.8532 78.2786 14.4685 78.2786 15.1912V22.048H75.7745Z"
          fill="#0D1117"
        />
        <path
          d="M86.7582 9.72629C86.6695 9.68339 86.4976 9.61886 86.2396 9.53814C85.979 9.45762 85.6378 9.41727 85.2079 9.41727C84.8264 9.41727 84.504 9.53289 84.246 9.76119C83.9881 9.98956 83.8591 10.363 83.8591 10.8789V11.3867H86.1617V13.6006H83.8806V22.056H81.1347V13.6006H79.7188V11.3867H81.1347V10.8117C81.1347 10.1185 81.2234 9.53281 81.4007 9.05187C81.578 8.57362 81.8252 8.18133 82.1423 7.88041C82.4593 7.57679 82.8328 7.3538 83.26 7.21408C83.6899 7.07428 84.1547 7.00449 84.6571 7.00449C85.3208 7.00449 85.8689 7.06629 86.3041 7.19263C86.7394 7.31897 86.9946 7.40221 87.0672 7.44789L86.7582 9.72629Z"
          fill="#0D1117"
        />
        <path
          d="M94.241 16.6664C94.241 16.223 94.1873 15.8012 94.0852 15.4009C93.983 15.0005 93.8272 14.6566 93.6203 14.3691C93.4134 14.079 93.1636 13.8506 92.868 13.6813C92.5725 13.5093 92.2259 13.4234 91.8282 13.4234C91.4306 13.4234 91.0813 13.5094 90.7884 13.6813C90.4929 13.8505 90.2403 14.079 90.0334 14.3691C89.8292 14.6566 89.6734 15.0005 89.5686 15.4009C89.4665 15.8012 89.4155 16.223 89.4155 16.6664C89.4155 17.1258 89.4665 17.553 89.5686 17.9453C89.6734 18.3376 89.8239 18.6815 90.0227 18.977C90.2215 19.2726 90.4741 19.5063 90.775 19.6783C91.0786 19.8475 91.4306 19.9335 91.8282 19.9335C92.2259 19.9335 92.5725 19.8475 92.868 19.6783C93.1636 19.5063 93.4134 19.2726 93.6203 18.977C93.8272 18.6815 93.9831 18.3376 94.0852 17.9453C94.1873 17.553 94.241 17.1258 94.241 16.6664ZM96.9412 16.6781C96.9412 17.3739 96.8338 18.0564 96.6188 18.7281C96.4066 19.3998 96.0895 19.9989 95.6677 20.5309C95.2486 21.0629 94.7166 21.4901 94.0744 21.8153C93.4323 22.1404 92.6826 22.3016 91.8282 22.3016C90.9711 22.3016 90.2215 22.1403 89.582 21.8153C88.9399 21.4902 88.4079 21.0629 87.9861 20.531C87.5669 19.999 87.2499 19.3998 87.0349 18.7281C86.82 18.0564 86.7152 17.3739 86.7152 16.6781C86.7152 15.9849 86.82 15.3078 87.0349 14.6414C87.2499 13.9778 87.5669 13.384 87.9861 12.8601C88.4079 12.3362 88.9399 11.917 89.582 11.6C90.2215 11.2829 90.9711 11.1217 91.8282 11.1217C92.6826 11.1217 93.4296 11.283 94.0637 11.6C94.6977 11.917 95.2297 12.3362 95.6569 12.8601C96.0868 13.384 96.4066 13.9778 96.6188 14.6414C96.8338 15.3078 96.9412 15.9849 96.9412 16.6781Z"
          fill="#0D1117"
        />
        <path
          d="M105.461 22.0561V15.9705C105.461 15.1591 105.292 14.5304 104.953 14.087C104.612 13.6464 104.142 13.4234 103.535 13.4234C103.108 13.4234 102.713 13.5335 102.353 13.7565C101.99 13.9769 101.675 14.2214 101.409 14.4874V22.0561H98.6662V11.3868H101.168L101.299 12.2278C101.729 11.8758 102.21 11.6017 102.75 11.411C103.288 11.2175 103.83 11.1235 104.376 11.1235C104.937 11.1235 105.453 11.2067 105.926 11.376C106.399 11.548 106.805 11.8166 107.143 12.1847C107.484 12.5528 107.745 13.0338 107.93 13.6222C108.113 14.2133 108.207 14.9226 108.207 15.7475V22.0561H105.461Z"
          fill="#0D1117"
        />
        <path
          d="M116.746 15.4385C116.716 15.0704 116.638 14.7479 116.512 14.4766C116.386 14.2025 116.23 13.9822 116.045 13.8129C115.859 13.641 115.66 13.5174 115.445 13.4341C115.228 13.3536 115.01 13.3132 114.787 13.3132C114.312 13.3132 113.86 13.4905 113.43 13.8452C113 14.1999 112.748 14.7291 112.673 15.4385H116.746ZM112.608 17.4079C112.667 18.308 112.947 18.9582 113.452 19.3558C113.954 19.7535 114.607 19.955 115.405 19.955C115.776 19.955 116.125 19.9201 116.45 19.8556C116.775 19.7885 117.066 19.705 117.318 19.6003C117.571 19.4982 117.786 19.3934 117.963 19.2913C118.14 19.1866 118.272 19.0898 118.363 19.0012L119.094 21.2393C118.989 21.3118 118.823 21.4113 118.594 21.5375C118.366 21.6639 118.081 21.7793 117.743 21.8922C117.404 22.0023 117.004 22.0964 116.547 22.1797C116.09 22.2602 115.588 22.3005 115.042 22.3005C114.346 22.3005 113.688 22.1904 113.06 21.9701C112.433 21.7471 111.883 21.4032 111.412 20.9383C110.94 20.4735 110.563 19.8958 110.284 19.2027C110.002 18.5094 109.862 17.6819 109.862 16.7227C109.862 15.8226 109.991 15.0247 110.249 14.3315C110.507 13.6383 110.862 13.0552 111.31 12.5823C111.762 12.1121 112.286 11.7494 112.882 11.4968C113.481 11.247 114.118 11.1207 114.798 11.1207C115.478 11.1207 116.104 11.2336 116.681 11.4538C117.256 11.6742 117.753 12.0181 118.175 12.4829C118.594 12.9477 118.928 13.5496 119.169 14.2884C119.414 15.0246 119.535 15.9033 119.535 16.9215V17.4079L112.608 17.4079Z"
          fill="#0D1117"
        />
      </g>
      <defs>
        <clipPath id="clip0_43_169">
          <rect width="120.471" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}