export default function Logo(): JSX.Element {
  return (
    <svg width="142" height="38" viewBox="0 0 142 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>HSBC</title>
      <g clipPath="url(#clip0_99_72)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0447 38.0447L0 19.0447L19.0447 0V38.0447ZM19.0449 0L38.0449 19.0447L19.0895 38H57.0002L38.0449 19.0446L57.0449 0V38.0447L76.0449 19.0447L57.0449 0H19.0449ZM92.7201 20.1624H85.8354V26.9577H82.393V11.0424H85.8354V17.5694H92.7201V11.0424H96.1625V26.9577H92.7201V20.1624ZM98.1742 22.0847C98.2189 25.8847 101.035 27.2706 104.478 27.2706C107.607 27.2706 111.094 25.8847 111.139 22.3082C111.139 19.6259 109.395 18.687 106.668 17.8376L105.327 17.4353C103.092 16.72 102.019 16.3623 102.019 15.2C102.019 13.7694 103.271 13.0988 104.567 13.0988C106.177 13.0988 107.026 13.9482 107.16 15.3788H110.602C110.558 11.8471 107.875 10.7741 104.791 10.7741C101.795 10.7741 98.4872 11.9812 98.4872 15.5129C98.4872 18.3741 100.991 19.3576 103.494 20.0729L104.657 20.3859C106.445 20.9223 107.518 21.28 107.518 22.5318C107.518 24.0965 105.908 24.8118 104.522 24.8118C102.645 24.8118 101.661 23.7835 101.617 22.0847H98.1742ZM113.061 11.087H118.605C119.633 11.0423 120.706 11.087 121.734 11.2659C123.657 11.7129 125.132 12.9647 125.132 15.1106C125.132 17.167 123.835 18.1953 121.958 18.687C124.104 19.0894 125.713 20.1623 125.713 22.5318C125.713 26.1529 122.137 26.9576 119.365 26.9576H113.106L113.061 11.087ZM118.605 17.7035C120.125 17.7035 121.689 17.3906 121.689 15.5576C121.689 13.9035 120.259 13.4565 118.828 13.4565H116.414V17.7035H118.605ZM118.918 24.5882C120.527 24.5882 122.092 24.2306 122.092 22.2635C122.092 20.2965 120.751 19.9388 119.097 19.9388H116.369V24.5882H118.918ZM127.233 19.1341C127.233 24.007 129.513 27.2705 134.654 27.2705C137.963 27.2705 140.868 25.9294 141.226 22.2635H137.65C137.426 23.8729 136.264 24.7223 134.699 24.7223C131.704 24.7223 130.765 21.7717 130.765 19.2235C130.765 16.6753 131.704 13.3223 134.833 13.3223C136.353 13.3223 137.426 14.127 137.605 15.7364H141.181C141.092 12.16 138.007 10.7294 134.833 10.7294C129.781 10.7294 127.233 14.2611 127.233 19.1341Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_99_72">
          <rect width="141.226" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}