export default function Logo(): JSX.Element {
  return (
    <svg width="250" height="104" viewBox="1338.7 1396.4 112.83 46.938">
      <title>Mercado Libre</title>

      <g transform="matrix(.74179 0 0 .74179 346.54 305.88)">
        <path d="m1378.1 1504.2h1.4v28h-5.3v-24.6c0-0.8 1.7-3.4 3.9-3.4z" />
        <path d="m1407.1 1510.4c-2.6 0-4.6 1.1-6 3.3v-9.5h-0.6c-2.6 0-4 1.4-4.7 2.6v-0.2 16.1c0 6.3 3.3 9.5 9.9 9.5 6.6-0.1 9.8-3.7 9.8-10.7 0-7.3-2.8-10.9-8.4-11.1zm-1.6 17.2c-3 0-4.5-2.2-4.4-6.4 0.2-4.1 1.6-6.1 4.5-6.1s4.3 2 4.5 6.1c0 4.3-1.6 6.4-4.6 6.4z" />
        <path d="m1418.6 1521.2c0.2-6.8 3.5-10.6 10.6-10.6h1.6v5.5h-2.5c-3 0-4.3 1.9-4.3 5v11h-5.4z" />
        <path d="m1439.2 1526.7c0.9 0.8 2.1 1.2 3.7 1.2 1.1 0 2.1-0.3 2.9-0.9s1.3-1.2 1.5-1.8h5c-0.8 2.5-2 4.2-3.7 5.3s-3.6 1.6-6 1.6c-1.6 0-3.1-0.3-4.4-0.8s-2.4-1.3-3.3-2.2c-0.9-1-1.6-2.1-2.1-3.4s-0.7-2.8-0.7-4.4c0-1.5 0.3-3 0.8-4.3s1.2-2.5 2.1-3.4c0.9-1 2-1.7 3.3-2.3s2.7-0.8 4.3-0.8c1.7 0 3.3 0.3 4.6 1s2.4 1.6 3.2 2.7 1.4 2.4 1.8 3.9c0.4 1.4 0.5 3 0.4 4.6h-14.9c0.2 1.9 0.7 3.2 1.5 4zm6.4-10.8c-0.7-0.7-1.7-1.1-3.1-1.1-0.9 0-1.7 0.2-2.3 0.5s-1.1 0.7-1.4 1.1c-0.4 0.5-0.6 0.9-0.8 1.4s-0.2 1-0.3 1.4h9.2c-0.1-1.5-0.6-2.6-1.3-3.3z" />
        <rect x="1385.2" y="1504.2" width="5.3" height="4.8" />
        <rect x="1385.2" y="1511.4" width="5.3" height="20.7" />

        <g>
          <path d="m1481.9 1493.1c-0.9 1-2.1 1.6-3.6 1.6s-2.7-0.6-3.6-1.6-1.2-2.7-1.2-4.7c0-2.1 0.4-3.6 1.2-4.6 0.9-1 2.1-1.6 3.6-1.6s2.7 0.6 3.6 1.6 1.2 2.7 1.2 4.6-0.3 3.5-1.2 4.7zm4.2-12.4c-1.6-2.1-4.3-3.3-7.8-3.3-3.4 0-6.2 1-7.8 3.3-1.6 2.1-2.6 4.7-2.6 7.6 0 3 0.9 5.6 2.6 7.6 1.6 2.1 4.3 3.2 7.8 3.2 3.4 0 6.2-1 7.8-3.2 1.6-2.1 2.6-4.7 2.6-7.6 0.1-2.8-0.8-5.4-2.6-7.6" />
          <path d="m1460.1 1493.1c-0.7 1-1.9 1.6-3.4 1.6s-2.7-0.6-3.3-1.6c-0.7-1.2-1-2.7-1-4.7 0-1.8 0.3-3.2 1-4.3 0.7-1.2 1.8-1.8 3.4-1.8 1 0 1.9 0.3 2.7 0.9 1.2 1 1.9 3 1.9 5.6-0.3 1.8-0.5 3.3-1.3 4.3zm6.2-21.8s-5.4-0.6-5.4 3.7v5.7c-0.6-0.9-1.3-1.6-2.4-2.3-0.9-0.6-2.1-0.7-3.3-0.7-2.7 0-4.8 1-6.5 3-1.6 1.9-2.4 4.8-2.4 8.4 0 3 0.7 5.6 2.4 7.3 1.6 1.9 4.8 2.7 7.6 2.7 9.9 0 9.7-8.4 9.7-8.4l0.3-19.4" />
          <path d="m1439.7 1490.6c0 1.8-0.6 3-1.5 3.7-1 0.6-2.1 1-3.2 1-0.7 0-1.3-0.1-1.8-0.6s-0.7-1-0.7-1.9c0-1 0.4-1.8 1.2-2.3 0.4-0.3 1.3-0.6 2.4-0.7l1.2-0.3c0.6-0.1 1-0.3 1.5-0.3 0.3-0.1 0.7-0.3 1-0.4zm2.7-11.7c-1.8-0.9-3.7-1.3-6-1.3-3.4 0-5.9 0.9-7.3 2.7-0.9 1.2-1.3 2.6-1.5 4.3h5.1c0.1-0.7 0.4-1.3 0.7-1.8 0.6-0.6 1.5-0.9 2.7-0.9s1.9 0.1 2.6 0.4c0.6 0.3 0.9 0.9 0.9 1.8 0 0.7-0.4 1.2-1.2 1.5-0.4 0.1-1.2 0.3-2.1 0.4l-1.8 0.1c-2.1 0.3-3.6 0.7-4.7 1.3-1.9 1-2.9 3-2.9 5.4 0 1.9 0.6 3.4 1.8 4.5 1.2 1 2.7 1.5 4.6 1.6 11.7 0.4 11.6-6.2 11.6-7.5v-7.6c0.1-2.2-0.7-4-2.5-4.9" />
          <path d="m1415.9 1482.2c1.3 0 2.3 0.4 3 1.2 0.4 0.6 0.7 1.3 0.7 2.1h5.7c-0.3-2.9-1.3-5-3-6-1.6-1.2-3.9-1.6-6.6-1.6-3.2 0-5.7 0.9-7.5 2.9s-2.7 4.7-2.7 8.1c0 3.2 0.7 5.6 2.4 7.5 1.6 1.9 4.2 2.9 7.6 2.9s6-1.2 7.8-3.4c1-1.5 1.6-3 1.8-4.7h-5.7c-0.1 1-0.4 1.9-1 2.6-0.6 0.6-1.5 1-2.9 1-1.8 0-3.2-0.9-3.7-2.6-0.3-0.9-0.6-2.1-0.6-3.6s0.1-2.9 0.6-3.7c0.9-1.8 2.1-2.7 4.1-2.7" />
          <path d="m1404 1477.7c-11.9 0-11.1 10.5-11.1 10.5v10.6h5.4v-9.9c0-1.6 0.1-2.9 0.6-3.6 0.7-1.3 2.1-2.1 4.3-2.1h0.6c0.3 0 0.6 0 0.9 0.1v-5.4h-0.4c-0.1-0.2-0.1-0.2-0.3-0.2" />
          <path d="m1377.2 1483.2c0.7-0.7 1.8-1.2 3.2-1.2 1.2 0 2.3 0.3 3.2 1s1.3 1.8 1.3 3.2h-9.2c0.3-1.2 0.7-2.2 1.5-3zm7.2 9.9c-0.1 0.3-0.4 0.6-0.7 0.7-0.7 0.6-1.8 0.7-3 0.7s-2.1-0.1-2.9-0.7c-1.3-0.7-2.1-2.3-2.1-4.2h14.8c0-1.8 0-3.2-0.1-4-0.3-1.6-0.7-3-1.6-4.2-0.9-1.3-2.1-2.4-3.4-3s-3-0.9-4.8-0.9c-3 0-5.4 0.9-7.2 2.9-1.8 1.9-2.9 4.6-2.9 8.1 0 3.7 1 6.5 3.2 8.1 2.1 1.6 4.5 2.6 7.2 2.6 3.3 0 5.9-1 7.6-3 1-1 1.6-2.1 1.8-3.2l-5.9 0.1" />
          <path d="m1368.2 1498.7h-5v-12.3c0-1.2-0.3-3.7-3.6-3.7-2.1 0-3.7 1.5-3.7 3.7v12.3h-5v-12.3c0-1.2-0.3-3.7-3.6-3.7-2.1 0-3.6 1.5-3.6 3.7v12.3h-5v-12.2c0-5.1 3.3-9 8.5-9 2.6 0 4.7 1 6.2 2.9 1.5-1.8 3.6-2.9 6.2-2.9 5.4 0 8.5 3.7 8.5 9l0.1 12.2" />
        </g>
      </g>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}