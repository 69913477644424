export default function Logo(): JSX.Element {
  return (
    <svg width="278" height="48" viewBox="0 0 278 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Forrester Research</title>
      <g clipPath="url(#clip0_40_49)">
        <mask
          id="mask0_40_49"
          style={{maskType: 'luminance'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="278"
          height="48"
        >
          <path d="M277.565 0H0V48H277.565V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_40_49)">
          <path
            d="M272.959 24.6136C270.551 24.6136 268.612 22.6769 268.612 20.2664C268.612 17.8581 270.551 15.9214 272.959 15.9214C275.366 15.9214 277.304 17.8581 277.304 20.2664C277.258 22.6769 275.32 24.6136 272.959 24.6136ZM272.959 16.391C270.831 16.391 269.132 18.1878 269.132 20.2643C269.132 22.391 270.879 24.1398 272.959 24.1398C275.036 24.1398 276.785 22.3471 276.785 20.2643C276.785 18.1878 275.036 16.391 272.959 16.391ZM274.093 22.535L272.913 20.5962H272.106V22.535H271.586V17.8581H273.099C273.907 17.8581 274.566 18.4236 274.566 19.2292C274.566 19.9388 274.093 20.3645 273.479 20.5523L274.708 22.537L274.093 22.535ZM273.053 18.3798H272.106V20.0786H273.053C273.619 20.0786 274.049 19.7468 274.049 19.2271C274.047 18.7576 273.617 18.3798 273.053 18.3798Z"
            fill="#1F2328"
          />
          <path
            d="M210.781 16.0633C210.637 16.0633 210.586 16.1113 209.267 16.1572C207.896 16.2031 205.39 16.2511 200.189 16.2511H197.783H196.316H193.907C188.755 16.2511 186.209 16.2031 184.833 16.1572C183.51 16.1113 183.466 16.0633 183.322 16.0633C182.707 16.0633 182.707 16.4431 182.707 17.6244V22.2052C182.707 22.441 182.707 23.0567 183.226 23.0567C183.794 23.0567 183.888 22.8668 184.787 21.024C185.353 19.7489 186.534 17.904 193.344 17.8122C194.047 17.8581 194.333 18 194.333 19.0873V42.336C194.333 44.2727 194.333 45.6417 191.831 45.7837C190.79 45.8776 190.08 45.9214 190.08 46.631C190.08 47.3885 190.742 47.3885 191.117 47.3885C192.067 47.3885 196.126 47.2007 196.979 47.2007C198.634 47.2007 201.231 47.3885 202.888 47.3885C203.259 47.3885 203.927 47.3885 203.927 46.631C203.927 45.8776 203.217 45.8275 202.176 45.7837C199.67 45.6417 199.67 44.2706 199.67 42.2379V19.465C199.67 18.1398 199.816 17.904 201.277 17.8122C207.514 18 208.646 19.7489 209.215 20.9781C210.113 22.8209 210.209 23.0087 210.776 23.0087C211.298 23.0087 211.298 22.3951 211.298 22.1614V17.5743C211.392 16.4431 211.392 16.0633 210.781 16.0633Z"
            fill="#1F2328"
          />
          <path
            d="M156.772 39.4518C156.301 39.4518 156.207 39.6397 155.307 41.3885C153.089 45.6397 150.017 45.6397 147.796 45.6397C143.587 45.6397 142.547 45.0741 142.547 41.5263V32.9259C142.547 31.417 142.925 31.417 145.336 31.417C149.445 31.417 150.816 31.4609 151.901 35.3384C152.137 36.2838 152.467 36.5197 152.845 36.5197C153.464 36.5197 153.464 35.8101 153.464 35.5283C153.464 35.1443 153.316 33.3997 153.316 33.0177C153.316 31.9367 153.652 26.7861 153.652 26.0765C153.652 25.511 153.418 25.367 153.087 25.367C152.849 25.367 152.563 25.4609 152.329 26.1245C151.43 28.7708 150.96 29.8581 145.432 29.8581C142.55 29.8581 142.55 29.4783 142.55 27.8692V20.6442C142.55 18.1398 142.735 17.808 145.24 17.808C152.327 17.808 153.556 19.7009 154.126 21.0219C155.023 22.8647 155.117 23.0525 155.683 23.0525C156.202 23.0525 156.202 22.439 156.202 22.201V17.6223C156.202 16.489 156.202 16.0612 155.587 16.0612C155.445 16.0612 155.399 16.1092 154.076 16.1551C152.707 16.201 150.154 16.249 145.004 16.249H142.593C138.342 16.249 136.214 16.201 135.078 16.1551C133.993 16.1092 133.945 16.0612 133.849 16.0612C133.519 16.0612 132.858 16.0612 132.858 16.7708C132.858 17.5263 133.567 17.5743 134.655 17.6223C137.157 17.7642 137.157 19.1332 137.157 21.168V42.2901C137.157 44.3207 137.157 45.6897 134.655 45.8317C133.611 45.9256 132.858 45.9736 132.858 46.6831C132.858 47.4365 133.519 47.4365 133.897 47.4365C134.229 47.4365 134.655 47.3927 135.458 47.3426C136.262 47.2925 137.489 47.2445 139.569 47.2445H140.183C140.467 47.2445 142.833 47.2925 145.663 47.3426C148.545 47.3864 152.041 47.4365 154.591 47.4365C156.061 47.4365 156.53 46.871 156.576 45.9235C156.72 44.0807 157.288 40.8689 157.288 40.2094C157.342 39.8755 157.198 39.4518 156.772 39.4518Z"
            fill="#1F2328"
          />
          <path
            d="M238.374 39.4518C237.898 39.4518 237.807 39.6397 236.907 41.3885C234.687 45.6397 231.613 45.6397 229.392 45.6397C225.185 45.6397 224.147 45.0741 224.147 41.5263V32.9259C224.147 31.417 224.527 31.417 226.934 31.417C231.045 31.417 232.418 31.4609 233.501 35.3384C233.735 36.2838 234.069 36.5197 234.444 36.5197C235.06 36.5197 235.06 35.8101 235.06 35.5283C235.06 35.1443 234.918 33.3997 234.918 33.0177C234.918 31.9367 235.248 26.7861 235.248 26.0765C235.248 25.511 235.016 25.367 234.685 25.367C234.447 25.367 234.167 25.4609 233.933 26.1245C233.032 28.7708 232.56 29.8581 227.03 29.8581C224.15 29.8581 224.15 29.4783 224.15 27.8692V20.6442C224.15 18.1398 224.337 17.808 226.842 17.808C233.931 17.808 235.154 19.7009 235.724 21.0219C236.625 22.8647 236.715 23.0525 237.283 23.0525C237.802 23.0525 237.802 22.439 237.802 22.201V17.6223C237.802 16.489 237.802 16.0612 237.189 16.0612C237.047 16.0612 236.999 16.1092 235.674 16.1551C234.307 16.201 231.752 16.249 226.604 16.249H224.193C219.94 16.249 217.816 16.201 216.676 16.1551C215.591 16.1092 215.543 16.0612 215.453 16.0612C215.119 16.0612 214.454 16.0612 214.454 16.7708C214.454 17.5263 215.165 17.5743 216.25 17.6223C218.753 17.7642 218.753 19.1332 218.753 21.168V42.2901C218.753 44.3207 218.753 45.6897 216.25 45.8317C215.211 45.9256 214.454 45.9736 214.454 46.6831C214.454 47.4365 215.117 47.4365 215.493 47.4365C215.827 47.4365 216.25 47.3927 217.056 47.3426C217.855 47.2946 219.084 47.2445 221.165 47.2445H221.785C222.067 47.2445 224.423 47.2925 227.263 47.3426C230.145 47.3864 233.645 47.4365 236.195 47.4365C237.658 47.4365 238.13 46.871 238.178 45.9235C238.318 44.0807 238.888 40.8689 238.888 40.2094C238.938 39.8755 238.8 39.4518 238.374 39.4518Z"
            fill="#1F2328"
          />
          <path
            d="M172.506 28.1092C168.442 26.4125 165.466 25.1332 165.466 21.5416C165.466 19.559 167.075 16.6769 171.043 16.7228C175.294 16.7687 176.193 19.559 176.809 21.5416C177.327 23.1026 177.519 23.4324 178.038 23.4324C178.746 23.4324 178.746 23.0066 178.746 21.9193C178.746 17.4303 178.746 16.9607 178.128 16.6289C177.423 16.2491 174.401 15.4477 171.471 15.4477C164.997 15.4477 161.405 19.511 161.405 24.2338C161.405 29.6223 165.418 31.655 169.578 33.4497C173.261 35.1485 176.715 37.0852 176.715 41.0567C176.715 45.1701 172.696 46.583 170.573 46.583C166.936 46.583 163.44 43.5131 162.493 39.0699C162.401 38.5962 162.303 38.0786 161.879 38.0786C161.261 38.0786 161.219 38.7882 161.219 38.9718C161.171 40.8188 161.407 42.9913 161.505 43.6508C161.643 44.9217 161.833 45.9652 162.403 46.3451C163.296 47.0045 165.85 47.9958 169.818 47.9958C176.623 47.9958 180.783 43.2751 180.783 37.6049C180.728 31.8428 175.388 29.3405 172.506 28.1092Z"
            fill="#1F2328"
          />
          <path
            d="M52.3054 15.4477C42.5259 15.4477 35.1047 22.4891 35.1047 31.655C35.1047 41.3885 43.3252 48 52.1635 48C60.7618 48 69.1263 41.0546 69.1263 31.1812C69.1722 22.391 61.7551 15.4477 52.3054 15.4477ZM52.5871 46.631C45.8776 46.631 41.0588 38.8383 41.0588 30.6157C41.0588 23.3844 45.5478 16.8668 51.7378 16.8668C59.6744 16.8668 63.2181 25.3732 63.2181 31.4191C63.2181 38.7402 59.5325 46.631 52.5871 46.631Z"
            fill="#1F2328"
          />
          <path
            d="M31.9388 3.87131C31.8449 3.87131 31.7969 3.96522 29.8581 4.01322C27.9694 4.05913 24.1899 4.15305 16.6769 4.15305H15.12C7.9847 4.15305 5.33843 4.05705 3.49565 4.01322C1.69878 3.91931 1.60487 3.87131 1.46504 3.87131C0.945391 3.87131 0 3.87131 0 4.8647C0 5.856 1.0873 5.952 2.60035 6.09392C6.18991 6.32974 6.18991 8.26644 6.18991 11.2424V30.7096V41.1527C6.18991 43.561 6.18991 45.216 3.11791 45.4038C1.84278 45.5457 0.991304 45.5917 0.991304 46.4431C0.991304 47.3405 1.79478 47.3405 2.26643 47.3405C3.44974 47.3405 8.55026 47.1089 9.58956 47.1089C11.6223 47.1089 14.9301 47.3405 16.9607 47.3405C17.4344 47.3405 18.2358 47.3405 18.2358 46.4431C18.2358 45.4977 17.3864 45.4477 16.0633 45.4038C12.9913 45.216 12.9913 43.561 12.9913 41.0588V30.7534V29.9541C12.9913 27.9193 12.9913 27.072 14.1725 26.7861C14.928 26.5983 18.2838 26.5983 20.4083 26.5983C22.2052 26.5983 26.8362 26.5983 27.8275 30.5197C27.9694 30.8995 28.4431 32.7402 28.6289 33.2619C28.7708 33.6334 29.0546 34.439 29.7642 34.3951C30.6136 34.3471 30.6136 33.5437 30.6136 33.0219L30.3297 29.6223C30.1878 27.9673 30.1878 26.7402 30.1878 26.0807C30.1878 24.9913 30.4257 20.0765 30.4257 19.0852C30.4257 18.3757 30.1878 17.904 29.5763 17.8539C28.9127 17.808 28.583 18.4195 28.4431 19.033C27.5437 22.7666 26.5023 24.3736 20.6463 24.3736C19.561 24.3736 15.2598 24.3736 14.1725 24.2358C13.2292 24.0939 13.0414 24 13.0414 20.1266V10.0132C13.0414 6.61148 14.2706 6.47166 16.7249 6.47166C27.9715 6.47166 28.9169 8.03061 31.0414 12.8494C31.3252 13.463 31.607 14.3144 32.4125 14.3144C33.216 14.3144 33.216 13.1812 33.216 12.9433V5.19444C33.168 4.25113 33.0762 3.87131 31.9388 3.87131Z"
            fill="#1F2328"
          />
          <path
            d="M98.3249 45.8755C96.9078 45.6877 96.2442 44.4083 95.2049 42.6595L90.3381 34.0675C89.2049 31.9847 88.5934 31.655 87.9297 31.2751C89.6306 30.9454 94.7812 29.7183 94.7812 23.856C94.7812 22.1092 94.0237 16.0153 85.1896 16.0153C83.6765 16.0153 77.063 16.2031 75.6918 16.2031C75.0783 16.2031 72.6219 16.0153 72.1461 16.0153C70.4932 16.0153 70.3033 16.0153 70.3033 16.7228C70.3033 17.4323 71.0129 17.4783 72.1002 17.5283C74.5544 17.6682 74.5544 19.0393 74.5544 21.0741V42.2901C74.5544 44.3645 74.5544 45.6897 72.1002 45.8817C71.0588 45.9256 70.3033 45.9757 70.3033 46.6852C70.3033 47.4386 70.967 47.3948 71.3447 47.3948C72.2421 47.3948 76.3054 47.207 77.1548 47.207C77.3447 47.207 82.2115 47.3948 83.1548 47.3948C83.5325 47.3948 84.1962 47.4386 84.1962 46.7311C84.1962 45.9777 83.4866 45.9277 82.3993 45.8838C79.8971 45.7377 79.8971 44.3666 79.8971 42.2922V33.312C79.8971 32.4647 79.991 32.1788 80.8424 32.1788C83.111 32.1788 83.3489 32.3666 84.4341 34.5433L90.48 45.9277C91.0456 46.967 91.2355 47.4407 92.1329 47.4407C92.6066 47.4407 98.087 47.4407 98.7005 47.4407C99.0303 47.4407 99.5499 47.3468 99.5499 46.825C99.648 46.2553 99.3621 46.0633 98.3249 45.8755ZM82.4473 30.8515C79.9451 30.8515 79.9451 30.7534 79.9451 29.0087V20.5983C79.9451 18.6595 79.991 17.4323 82.3075 17.4323C88.4953 17.4323 89.2049 22.7708 89.2049 24.5677C89.2049 30.8515 84.2442 30.8515 82.4473 30.8515Z"
            fill="#1F2328"
          />
          <path
            d="M129.698 45.8755C128.283 45.6877 127.619 44.4083 126.578 42.6595L121.711 34.0675C120.578 31.9847 119.967 31.655 119.305 31.2751C121.004 30.9454 126.154 29.7183 126.154 23.856C126.154 22.1092 125.399 16.0153 116.565 16.0153C115.05 16.0153 108.438 16.2031 107.065 16.2031C106.451 16.2031 103.995 16.0153 103.521 16.0153C101.866 16.0153 101.677 16.0153 101.677 16.7228C101.677 17.4323 102.386 17.4783 103.473 17.5283C105.93 17.6682 105.93 19.0393 105.93 21.0741V42.2901C105.93 44.3645 105.93 45.6897 103.473 45.8817C102.432 45.9256 101.677 45.9757 101.677 46.6852C101.677 47.4386 102.34 47.3948 102.72 47.3948C103.617 47.3948 107.679 47.207 108.528 47.207C108.718 47.207 113.587 47.3948 114.53 47.3948C114.912 47.3948 115.569 47.4386 115.569 46.7311C115.569 45.9777 114.86 45.9277 113.775 45.8838C111.27 45.7377 111.27 44.3666 111.27 42.2922V33.312C111.27 32.4647 111.366 32.1788 112.214 32.1788C114.484 32.1788 114.72 32.3666 115.805 34.5433L121.853 45.9277C122.419 46.967 122.607 47.4407 123.506 47.4407C123.978 47.4407 129.46 47.4407 130.072 47.4407C130.403 47.4407 130.923 47.3468 130.923 46.825C130.975 46.2553 130.739 46.0633 129.698 45.8755ZM113.823 30.8515C111.316 30.8515 111.316 30.7534 111.316 29.0087V20.5983C111.316 18.6595 111.364 17.4323 113.679 17.4323C119.866 17.4323 120.58 22.7708 120.58 24.5677C120.532 30.8515 115.619 30.8515 113.823 30.8515Z"
            fill="#1F2328"
          />
          <path
            d="M269.745 45.8755C268.328 45.6877 267.667 44.4083 266.627 42.6595L261.807 34.1113C260.671 32.0327 260.058 31.703 259.4 31.3231C261.097 30.9892 266.252 29.7621 266.252 23.904C266.252 22.1572 265.494 16.0612 256.656 16.0612C255.143 16.0612 248.529 16.2511 247.16 16.2511C246.545 16.2511 244.086 16.0612 243.617 16.0612C241.962 16.0612 241.774 16.0612 241.774 16.7708C241.774 17.4783 242.481 17.5263 243.567 17.5743C246.023 17.7141 246.023 19.0873 246.023 21.1179V42.3339C246.023 44.4083 246.023 45.7336 243.567 45.9214C242.527 45.9736 241.774 46.0195 241.774 46.729C241.774 47.4824 242.431 47.4386 242.813 47.4386C243.711 47.4386 247.774 47.2466 248.621 47.2466C248.813 47.2466 253.68 47.4386 254.623 47.4386C255.003 47.4386 255.663 47.4824 255.663 46.7791C255.663 46.0216 254.957 45.9757 253.868 45.9235C251.368 45.7857 251.368 44.4104 251.368 42.336V33.36C251.368 32.5043 251.457 32.2226 252.309 32.2226C254.579 32.2226 254.815 32.4146 255.903 34.5871L261.949 45.9757C262.514 47.015 262.704 47.4845 263.601 47.4845C264.079 47.4845 269.558 47.4845 270.169 47.4845C270.499 47.4845 271.018 47.3906 271.018 46.8689C271.071 46.2553 270.831 46.0633 269.745 45.8755ZM253.87 30.8515C251.37 30.8515 251.37 30.7534 251.37 29.0087V20.5983C251.37 18.6595 251.416 17.4323 253.728 17.4323C259.922 17.4323 260.63 22.7708 260.63 24.5677C260.627 30.8515 255.665 30.8515 253.87 30.8515Z"
            fill="#1F2328"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_40_49">
          <rect width="277.565" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}