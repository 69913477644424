export default function Logo(): JSX.Element {
  return (
    <svg width="165" height="39" viewBox="0 0 165 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Gartner</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.514 17.6239H157.253C153.651 17.6239 151.774 20.5238 151.774 23.2949V35.8147H145.38V12.0837H151.436L151.567 15.0469C152.841 13.0176 155.065 12.0837 157.804 12.0837H159.978L159.514 17.6239Z"
        fill="#1F2328"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.645 35.8148H109.239V23.2699C109.239 20.6429 109.015 17.3376 105.326 17.3376C101.769 17.3376 100.356 19.9012 100.356 22.8971V35.8148H93.9541V12.0837H99.8878L100.022 15.1161C101.296 13.0868 103.665 11.5052 107.159 11.5052C113.605 11.5052 115.645 16.1173 115.645 20.9158V35.8148Z"
        fill="#1F2328"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.167 30.2706L90.7198 35.6667C89.1846 36.0875 87.8201 36.2298 86.1774 36.2298C80.1477 36.2298 78.3937 33.4567 78.3937 27.8953V5.39417H84.7976V12.0836H91.167L90.7198 17.6239H84.7976V27.4149C84.7976 29.6441 85.4059 30.8509 87.6992 30.8509C88.6856 30.8509 89.7987 30.7318 91.167 30.2706Z"
        fill="#1F2328"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.7989 17.624H74.0602C70.4581 17.624 68.6619 20.5181 68.6619 23.2949V35.8148H62.2676V12.0837H68.328L68.4566 15.047C69.7308 13.0177 71.9531 12.0837 74.7012 12.0837H76.294L75.7989 17.624Z"
        fill="#1F2328"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3858 33.1877C29.0487 35.513 24.7116 36.8505 18.9851 36.6852C8.36503 36.3585 0.706055 29.7594 0.706055 18.6078C0.706055 7.50231 8.4418 0.407396 18.987 0.245973C24.3374 0.16334 29.1197 1.52198 32.858 4.8273L28.354 9.38942C26.0396 7.41583 22.6371 6.25897 18.5687 6.44538C11.9307 6.749 7.92749 11.5859 7.84113 18.2273C7.75477 25.4202 12.1609 30.3821 19.0485 30.5646C22.2917 30.6453 24.6099 30.2091 26.623 29.3059V21.9496H19.4649L19.9447 15.9117H33.3858V33.1877Z"
        fill="#1F2328"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.521 26.2005H124.975C125.474 29.231 127.854 31.0547 130.819 31.1431C133.701 31.2238 135.31 30.3456 137.419 28.2106L141.595 31.3987C138.63 35.0595 135.07 36.3989 131.011 36.3989C123.874 36.3989 118.529 31.3987 118.529 23.9463C118.529 16.5094 123.847 11.5052 130.744 11.5052C138.346 11.5052 142.521 16.1173 142.521 24.3038V26.2005ZM136.108 21.2406C135.912 18.2466 133.667 16.3479 130.583 16.4709C127.472 16.5901 125.403 18.6136 124.975 21.2406H136.108Z"
        fill="#1F2328"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.2388 20.2759V35.8147H52.593L52.4548 33.4703C50.7238 35.6187 47.9085 36.3989 45.0203 36.3989C40.6641 36.3989 36.5458 34.2409 36.4613 29.231C36.3327 21.352 45.314 20.7909 50.9522 20.7909H52.0844V20.4815C52.0844 17.8776 50.3592 16.4709 47.1217 16.4709C44.792 16.4709 42.4968 17.4068 40.7984 18.9999L37.1042 15.2391C40.4587 12.5199 43.9457 11.5052 47.6648 11.5052C55.0033 11.5052 58.2388 14.9028 58.2388 20.2759ZM52.0844 25.4587H51.1882C48.1599 25.4741 42.8403 25.6221 42.8576 28.7948C42.8902 33.2589 52.0844 31.8464 52.0844 26.4503V25.4587Z"
        fill="#1F2328"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155.159 33.9823C155.159 32.0307 156.612 30.5557 158.479 30.5557C160.316 30.5557 161.766 32.0307 161.766 33.9823C161.766 35.9688 160.316 37.4338 158.479 37.4338C156.612 37.4338 155.159 35.9688 155.159 33.9823ZM158.479 38.1166C160.691 38.1166 162.585 36.3735 162.585 33.9823C162.585 31.6185 160.691 29.8729 158.479 29.8729C156.235 29.8729 154.341 31.6185 154.341 33.9823C154.341 36.3735 156.235 38.1166 158.479 38.1166ZM157.614 34.3151H158.432L159.672 36.3735H160.465L159.135 34.2803C159.824 34.1934 160.355 33.8209 160.355 32.9692C160.355 32.0307 159.799 31.6185 158.689 31.6185H156.906V36.3735H157.614V34.3151ZM157.614 33.6943V32.2293H158.584C159.076 32.2293 159.608 32.3361 159.608 32.9221C159.608 33.6546 159.062 33.6943 158.469 33.6943H157.614Z"
        fill="#1F2328"
      />
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}