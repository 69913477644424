export default function Logo(): JSX.Element {
  return (
    <svg width="69" height="40" viewBox="0 0 69 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Bolt</title>
      <g clipPath="url(#clip0_43_147)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.2616 9.82801e-06V30.0737H48.1259V1.5037L55.2616 9.82801e-06ZM34.9448 32.9238C36.9153 32.9238 38.5126 34.5079 38.5126 36.4619C38.5126 38.4159 36.9153 40 34.9448 40C32.9744 40 31.377 38.4159 31.377 36.4619C31.377 34.5079 32.9744 32.9238 34.9448 32.9238ZM34.9448 8.54054C41.0201 8.54054 45.9556 13.4251 45.9556 19.4595C45.9556 25.4939 41.02 30.3784 34.9448 30.3784C28.8597 30.3784 23.9341 25.4939 23.9341 19.4595C23.9341 13.4251 28.8696 8.54054 34.9448 8.54054ZM34.9448 22.9975C36.917 22.9975 38.5126 21.4152 38.5126 19.4595C38.5126 17.5037 36.917 15.9214 34.9448 15.9214C32.9726 15.9214 31.377 17.5037 31.377 19.4595C31.377 21.4152 32.9726 22.9975 34.9448 22.9975ZM12.4973 22.9975C13.7262 22.9975 14.7272 22.0049 14.7272 20.7862C14.7272 19.5676 13.7262 18.5749 12.4973 18.5749H7.14555V22.9975H12.4973ZM7.14555 7.07617V11.4988H11.1098C12.3387 11.4988 13.3397 10.5061 13.3397 9.28747C13.3397 8.0688 12.3387 7.07617 11.1098 7.07617H7.14555ZM19.068 14.172C20.7925 15.8624 21.8628 18.2015 21.8529 20.7862C21.8529 25.9165 17.6608 30.0737 12.4874 30.0737H0V0H11.0999C16.2733 0 20.4654 4.15725 20.4654 9.28747C20.4654 11.0762 19.96 12.7568 19.068 14.172ZM68.74 16.0197H65.1822V21.5725C65.1822 23.2531 65.7273 24.4914 67.1543 24.4914C68.0761 24.4914 68.75 24.285 68.75 24.285V29.4939C68.75 29.4939 67.2732 30.3784 65.2713 30.3784H65.1822C65.0929 30.3784 65.0136 30.3686 64.9244 30.3686H64.855C64.8154 30.3686 64.7658 30.3587 64.7262 30.3587C60.7421 30.1523 58.0365 27.6658 58.0365 23.3514V5.04177L65.1722 3.53808V8.94349H68.74V16.0197Z"
          fill="#0D1117"
        />
      </g>
      <defs>
        <clipPath id="clip0_43_147">
          <rect width="69" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}