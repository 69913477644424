export default function Logo(): JSX.Element {
  return (
    <svg width="61" height="32" viewBox="0 0 61 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>3M</title>
      <g clipPath="url(#clip0_1_605)">
        <path d="M42.2721 19.5456L36.7445 0H24.3342V5.23796C22.7171 2.09889 18.8113 0.215449 13.3648 0.215449C6.96619 0.215449 1.26026 3.22015 0.954463 10.2744H9.14153C9.40331 8.09672 10.9694 7.01021 13.103 7.01021C14.8868 7.01021 16.6707 7.7469 16.6707 9.75313C16.6707 12.6235 13.6683 12.8853 11.2729 12.8853V18.2437C13.4482 18.2437 16.9325 18.5472 16.9325 21.6399C16.9325 23.4237 15.5378 25.2099 12.8853 25.2099C10.0566 25.2099 8.53225 23.5094 8.53225 20.6831H0C0 28.2146 5.26808 32 12.408 32C17.968 32 22.1658 30.3088 24.3365 27.0377V31.3513H33.5684V20.7248C33.5684 18.1973 33.2186 13.9764 32.9151 11.2335H33.0031L38.7044 31.349H45.8443L51.5456 11.2335H51.6337C51.2422 14.8451 50.9804 18.2437 50.9804 21.7673V31.3467H60.2099V0H47.8042L42.2721 19.5456ZM24.3342 16.7101C23.623 15.9154 22.7056 15.2784 21.5936 14.8451C22.8585 14.2243 23.755 13.381 24.3342 12.3408V16.7101Z" />
      </g>
      <defs>
        <clipPath id="clip0_1_605">
          <rect width="60.2099" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}