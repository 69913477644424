export default function Logo(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 941.6 467.1"
      xmlSpace="preserve"
    >
      <title>Elanco</title>
      <style type="text/css">
        {`
        .Elanco0{fill:none;}
        .Elanco1{clip-path:url(#SVGID_2_);}
        .Elanco2{clip-path:url(#SVGID_4_);fill:#0067B1;}
        .Elanco3{clip-path:url(#SVGID_6_);}
      `}
      </style>
      <rect x="9.4" y="4.7" className="Elanco0" width="922.7" height="457.7" />
      <g>
        <defs>
          <rect id="SVGID_1_" x="9.4" y="4.7" width="922.7" height="457.7" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" style={{overflow: 'visible'}} />
        </clipPath>
        <g className="Elanco1">
          <g>
            <defs>
              <rect id="SVGID_3_" x="-23.3" y="-32.5" width="988.1" height="532" />
            </defs>
            <clipPath id="SVGID_4_">
              <use xlinkHref="#SVGID_3_" style={{overflow: 'visible'}} />
            </clipPath>
            <path
              className="Elanco2"
              d="M902.7,332.5h4.8v12.8h2.2v-12.8h4.7v-1.9h-11.7V332.5z M919.8,345.3V332h0.1l4,13.3h1.8l4.1-13.3h0.1v13.3     h2.2v-14.7h-3.9l-3.2,10.9h-0.1l-3.5-10.9h-3.8v14.7H919.8z"
            />
          </g>
        </g>
      </g>
      <g>
        <defs>
          <rect id="SVGID_5_" x="9.4" y="4.7" width="922.7" height="457.7" />
        </defs>
        <clipPath id="SVGID_6_">
          <use xlinkHref="#SVGID_5_" style={{overflow: 'visible'}} />
        </clipPath>
        <g className="Elanco3">
          <path d="M621.3,242.5c-16.7,0-23.7,50.2-23.7,58.5c0,7.4,2.6,10.9,9.1,10.9c16.7,0,23.7-50.4,23.7-58.5    C630.4,245.3,627.4,242.5,621.3,242.5z" />
          <path d="M290.3,250.9c-8.6,10-13.7,33.4-13.7,48.3c0,8.1,2.3,11.6,7.9,11.6c4.9,0,13.2-8.4,14.2-12.3l11.1-52.5    c-1.4-1.2-3.5-1.6-4.9-1.6C301.9,244.4,296.1,244.2,290.3,250.9z" />
          <path d="M932.1,4.7L9.4,166.6l53,295.8l869.7-153.9L932.1,4.7z M163.6,337.2H66.5l34.4-161.8H195l-10.3,34.1h-49.4    l-5.8,27.2h40.4l-7,33h-40.4l-7,32.9H171L163.6,337.2z M206.7,340.2c-34.1,0-31.1-17.6-26-41.6l17.4-81.8    c5.9-27.7,6.4-36.5,6.2-43.3l42-7c0.5,5.8-0.9,18.1-3.5,30l-19,88.9c-4.4,21.1-4.9,26.7,4.2,26.7c1.4,0,2.8-0.2,4.4-0.7l1.2,25.1    C227.8,338.6,217.8,340.2,206.7,340.2z M310.6,341.8c-4.9-2.6-8.4-9.8-9.1-14.2c-5.3,5.6-17.2,13.2-31.1,13.2    c-24.4,0-34.6-15.3-34.6-40.9c0-25.5,10.2-52,25.8-67.6c13.7-13.7,28.3-18.1,49.5-18.1c16.5,0,36.9,2.8,45.8,7.2    c-3.5,8.6-7,23-9.1,32.9h0l-9.1,43.2c-0.9,4-0.7,12.3,4.6,18.1L310.6,341.8z M409,337.3l16.3-76.6c2.1-9.3,0.9-13.5-5.8-13.5    c-6.5,0-11.6,3.3-16.7,11.1l-17,79h-40.4l16-75.5c3.7-18.1,5.1-28.8,3.3-40.6l35.8-6.7c0.9,3.9,1.9,8.1,2.1,13    c9.1-8.2,23-13.1,36.3-13.1c30,0,33,17,29.3,34.4l-18.8,88.5H409z M508.3,340.3L508.3,340.3c-33.4,0-49.7-21.4-41.1-61.8    c8.6-40.6,33.2-64.1,68.5-64.1c17.2,0,26.5,4.4,36.2,14.2L548.2,253c-3.5-4.9-9.5-8.8-15.1-8.8c-9.3,0-16.3,7.4-21.4,31.8    c-5.1,24.4-4.6,34.4,7,34.4c9.1,0,17.4-4.4,24.2-10.7l12.1,23.7C539.4,336.1,523,340.3,508.3,340.3z M673.1,256.9    c0,31.4-17.4,83.4-73.4,83.4c-28.3,0-44.4-15.6-44.4-43.2c0-30.9,16.7-82.7,73.4-82.7C653.8,214.4,673.1,226.7,673.1,256.9    L673.1,256.9z" />
        </g>
      </g>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}