export default function Logo(): JSX.Element {
  return (
    <svg width="173" height="34" viewBox="0 0 173 34" xmlns="http://www.w3.org/2000/svg">
      <title>Telus</title>
      <path d="M102.97 10.0573V6.52588H90.3721V24.6954H102.97V21.0102H95.4725V16.9825H101.708V13.4554H95.4725V10.0573H102.97Z" />
      <path d="M125.185 21.11H117.314V6.52588H112.151V24.6954H125.185V21.11Z" />
      <path d="M166.769 13.4503C163.285 12.6654 162.065 12.5744 162.065 11.1883C162.065 9.90209 163.792 9.50202 164.785 9.50202C166.391 9.50202 168.288 9.87659 170.221 10.8677L171.627 7.52424C169.646 6.44426 167.104 5.99463 164.659 5.99463C159.854 5.99463 156.63 7.94399 156.495 11.7443C156.358 15.2102 159.176 16.5066 161.766 17.0415C164.262 17.5574 166.795 17.999 166.846 19.482C166.892 20.8498 165.662 21.4357 163.341 21.4357C161.192 21.4357 159.193 20.7449 157.151 19.8894L155.957 23.5236C158.305 24.5219 160.688 25.0721 163.145 25.0721C166.558 25.0721 169.049 24.5394 170.882 22.8182C172.153 21.6362 172.569 20.2093 172.569 18.8473C172.572 15.9754 170.782 14.3576 166.769 13.4503Z" />
      <path d="M83.0477 10.1127V6.52588H67.2021V10.1127H72.5554V24.6954H77.6879V10.1127H83.0477Z" />
      <path d="M142.799 6.52588V18.0697C142.799 18.9741 142.799 19.224 142.745 19.576C142.579 20.6764 141.513 21.3148 139.813 21.3374C138.553 21.317 137.631 20.9446 137.201 20.2865C136.985 19.9484 136.88 19.3406 136.88 18.37V6.52588H131.525V19.0477C131.525 20.7828 131.757 21.5662 132.541 22.5565C133.833 24.1721 136.353 25.0218 139.823 25.0218C139.862 25.0218 139.896 25.0218 139.937 25.0218C139.955 25.0218 139.987 25.0218 140.013 25.0218C144.659 25.0218 147.058 23.2102 147.751 21.5152C148.098 20.6932 148.123 20.304 148.123 18.6454V6.52588H142.799Z" />
      <path d="M69.6207 1.55077L39.5918 7.5293V9.62367L39.6873 9.5989C59.5248 4.77687 69.62 1.87652 69.7016 1.85611C69.8138 1.82696 69.8532 1.73952 69.8379 1.66591C69.8211 1.58867 69.7402 1.52454 69.6207 1.55077Z" />
      <path d="M44.1791 2.84797V2.84505V2.84797ZM45.2059 0.979497C43.7462 1.06257 41.0434 3.22472 39.5597 4.50875C33.6518 9.62008 29.259 14.3182 26.065 19.2386C19.4066 22.805 10.9161 26.4728 2.48758 29.3352L2.28427 29.4037L0.662109 33.7717L1.58833 33.4139C6.95034 31.3706 15.8999 27.5331 24.0129 23.2671C23.556 24.4855 23.3257 25.6056 23.3257 26.6265C23.3257 27.388 23.4532 28.0964 23.7076 28.7398C24.2177 30.0253 25.2233 30.9989 26.6174 31.552C28.6935 32.3806 31.5261 32.3959 34.7581 31.4172C43.0146 28.9147 52.808 21.231 54.9403 19.7028L55.0219 19.6453C55.1057 19.5848 55.1116 19.4937 55.0766 19.4317C55.0299 19.3611 54.9316 19.3421 54.8507 19.3917H54.8499H54.8507L54.7457 19.4551C52.4575 20.8287 41.3385 27.4303 34.1109 29.3687C30.4294 30.3562 27.6238 29.8169 26.5656 28.3369C26.1984 27.8311 26.0213 27.1913 26.0213 26.4451C26.0213 24.9883 26.707 23.1337 28.0275 21.0721C29.243 20.3806 30.3784 19.7043 31.4015 19.0615C38.4527 15.0987 46.5766 7.3938 46.9235 2.69056C46.925 2.6585 46.925 2.62133 46.925 2.58635C46.925 2.18045 46.7494 1.76289 46.4411 1.45463C46.1103 1.11723 45.6745 0.948891 45.2059 0.979497ZM44.4196 3.22108C44.8823 2.92157 45.0157 3.10885 44.8794 3.44699C44.6594 3.98698 43.6413 7.23567 36.0822 13.4416C34.1095 15.0572 32.7067 15.9644 29.8661 17.6463C34.5176 11.1278 41.3086 5.24113 44.4196 3.22108Z" />
      <path d="M39.4936 7.54907L7.90008 14.3401L6.68164 17.626L39.5919 9.62304L40.61 9.04808L40.5816 7.55272L39.4936 7.54907Z" />
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}