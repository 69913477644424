export default function Logo(): JSX.Element {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>General Motors</title>
      <path d="M6.19355 0C2.77295 0 0 2.77295 0 6.19355V41.8064C0 45.2271 2.77295 48 6.19355 48H41.8064C45.2271 48 48 45.2271 48 41.8064V6.19355C48 2.77295 45.2271 0 41.8064 0H6.19355ZM6.19355 3.09677H41.8064C43.5168 3.09677 44.9032 4.48325 44.9032 6.19355V41.8064C44.9032 43.5168 43.5168 44.9032 41.8064 44.9032H6.19355C4.48325 44.9032 3.09677 43.5168 3.09677 41.8064V6.19355C3.09677 4.48325 4.48325 3.09677 6.19355 3.09677Z" />
      <path d="M21.677 32.5163V35.613H38.7093V32.5163H21.677Z" />
      <path d="M21.677 12.387V29.0322H25.1609V15.4838H28.6448V29.0322H32.1286V15.4838H34.4512C35.0926 15.4838 35.6125 16.0037 35.6125 16.6451V29.0322H39.0964V16.258C39.0964 14.1201 37.3633 12.387 35.2254 12.387H21.677Z" />
      <path d="M12.7747 12.387C10.6368 12.387 8.90373 14.1201 8.90373 16.258V25.1612C8.90373 27.2991 10.6368 29.0322 12.7747 29.0322H15.8715V29.8064C15.8715 31.3029 14.485 32.516 12.7747 32.516H10.8392V35.6128H12.7747C16.4091 35.6128 19.3553 33.0132 19.3553 29.8064V12.387H12.7747ZM13.5489 15.4838H15.8715V25.9354H13.5489C12.9075 25.9354 12.3876 25.4155 12.3876 24.7741V16.6451C12.3876 16.0037 12.9075 15.4838 13.5489 15.4838Z" />
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}