export default function Logo(): JSX.Element {
  return (
    <svg width="116" height="32" viewBox="0 0 116 32" xmlns="http://www.w3.org/2000/svg">
      <title>Fidelity</title>
      <g clipPath="url(#clip0_71_37378)">
        <path d="M-13.4225 29.7849C-5.46678 29.7849 0.979166 23.4404 0.979166 15.6122C0.979166 7.78694 -5.46678 1.44249 -13.4225 1.44249C-21.3763 1.44249 -27.8242 7.78694 -27.8242 15.6122C-27.8242 23.4404 -21.3763 29.7849 -13.4225 29.7849Z" />
        <path d="M10.5228 25.2752H3.44531L9.11265 5.36881H23.6209L22.2384 10.0927H14.8342L13.8849 13.4815H21.289L20.0407 17.8296H12.6376L10.5228 25.2752Z" />
        <path d="M26.9018 25.2752H19.9062L24.0549 10.7326H31.0504L26.9018 25.2752ZM31.4304 9.34501H24.4348L25.5736 5.36881H32.5692L31.4304 9.34501Z" />
        <path d="M45.0528 25.2752H38.1924L38.6799 23.6478C37.2964 24.7946 35.8872 25.6218 33.6363 25.6218C31.3577 25.6218 29.9219 24.5537 29.9219 22.2855C29.9219 19.7784 30.9255 16.042 32.4166 13.4271C33.5011 11.4793 35.453 10.4655 37.7582 10.4655C39.7368 10.4655 41.2013 11.32 41.7716 12.3862L43.7245 5.36881H50.7201L45.0528 25.2752ZM39.7921 15.0545C39.1674 15.0545 38.5447 15.2428 38.1924 16.0954C37.6763 17.3227 37.2155 18.5238 37.2155 19.724C37.2155 20.2852 37.623 20.7649 38.3 20.7649C38.8427 20.7649 39.195 20.6047 39.4931 20.4192L40.9042 15.6419C40.6595 15.2943 40.2253 15.0545 39.7921 15.0545Z" />
        <path d="M64.8066 18.924H55.1535C54.9621 19.6969 54.7736 20.2319 54.7736 20.8446C54.7736 21.1388 54.8279 21.8856 55.8038 21.8856C56.7798 21.8856 57.214 21.5117 57.6206 20.0979H64.481C63.6135 24.2344 58.6242 25.6472 55.5601 25.6472C51.4105 25.6472 48.1836 25.0607 48.1836 21.1388C48.1836 18.9774 49.2948 15.4012 50.7602 13.5078C52.5227 11.2395 55.5867 10.387 58.4081 10.387C62.7461 10.387 65.5655 11.2667 65.5655 14.8944C65.5655 15.776 65.1865 17.7229 64.8066 18.924ZM57.8101 14.0418C56.9426 14.0418 56.3466 14.6283 55.9667 16.0955H58.8403C58.9222 15.8022 59.0307 15.3488 59.0307 15.0818C59.0307 14.2817 58.5423 14.0418 57.8101 14.0418Z" />
        <path d="M71.8384 25.2752H64.8418L70.5091 5.36881H77.5057L71.8384 25.2752Z" />
        <path d="M81.0816 25.2752H74.084L78.2336 10.7326H85.2302L81.0816 25.2752ZM85.6091 9.34501H78.6135L79.7523 5.36881H86.7489L85.6091 9.34501Z" />
        <path d="M98.1872 10.7324L98.7961 14.0143H93.4416L91.8686 19.5383C91.7334 19.9646 91.6238 20.4977 91.6238 20.8453C91.6238 21.4852 91.8962 21.6998 92.8446 21.6998H94.3366L93.3054 25.275H87.0687C85.0072 25.275 84.2207 24.2875 84.2207 22.7922C84.2207 22.1804 84.3026 21.5114 84.5197 20.7647L88.316 7.50381H95.3126L94.3899 10.7324H98.1872Z" />
        <path d="M107.632 10.7324L103.836 18.7907L103.944 10.7324H96.8669L98.2228 25.275C98.0491 26.1265 97.7629 26.6062 97.2202 26.8431C96.6261 27.1034 95.2831 27.0577 94.8104 27.0315L94.7245 27.0362L93.6094 30.9872L98.3846 30.9918C101.205 30.9918 102.589 29.5965 104.595 26.5019L114.792 10.732L107.632 10.7324Z" />
        <path d="M108.736 25.0077C108.035 25.0077 107.469 24.4494 107.469 23.7609C107.469 23.0725 108.035 22.5141 108.736 22.5141C109.435 22.5141 110.003 23.0725 110.003 23.7609C110.003 24.4494 109.435 25.0077 108.736 25.0077ZM108.736 22.2452C107.886 22.2452 107.195 22.9259 107.195 23.7609C107.195 24.596 107.886 25.2766 108.736 25.2766C109.583 25.2766 110.275 24.596 110.275 23.7609C110.275 22.9259 109.583 22.2452 108.736 22.2452Z" />
        <path d="M108.47 23.6564V23.2185H108.681C108.861 23.2146 109.059 23.2263 109.059 23.436C109.059 23.6458 108.861 23.6564 108.681 23.6564H108.47ZM109.351 23.4477C109.351 23.1457 109.172 22.9757 108.789 22.9757H108.191V24.5391H108.47V23.8992H108.681L109.079 24.5391H109.384L108.969 23.8769C109.187 23.8507 109.351 23.7186 109.351 23.4477Z" />
        <path d="M107.62 10.7678L103.835 18.7989L103.944 10.7678H96.8912L98.2422 25.26C98.0695 26.1086 97.7843 26.5863 97.2435 26.8232C96.6514 27.0815 95.3132 27.0359 94.8415 27.0097L94.7567 27.0143L93.6445 30.9527L98.403 30.9566C101.214 30.9566 102.593 29.5671 104.592 26.4832L114.754 10.7677L107.62 10.7678Z" />
      </g>
      <defs>
        <clipPath id="clip0_71_37378">
          <rect width="115.2" height="32" fill="white" transform="translate(0.800781)" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}