export default function Logo(): JSX.Element {
  return (
    <svg width="109" height="29" viewBox="0 0 109 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Adobe</title>
      <g clipPath="url(#clip0_43_158)">
        <path d="M11.9922 0H0V28.6797L11.9922 0Z" fill="#0D1117" />
        <path d="M20.4297 0H32.4063V28.6797L20.4297 0Z" fill="#0D1117" />
        <path d="M16.211 10.5703L23.8438 28.6797H18.836L16.5547 22.9141H10.9688L16.211 10.5703Z" fill="#0D1117" />
        <path
          d="M51.9706 18.2384L53.3847 22.2931C53.4316 22.3947 53.5019 22.4416 53.6269 22.4416H56.3456C56.4941 22.4416 56.5175 22.3712 56.4941 22.2228L50.8769 6.62125C50.8535 6.49625 50.83 6.47281 50.705 6.47281H47.33C47.2363 6.47281 47.1816 6.54312 47.1816 6.64468C47.1347 7.465 47.0644 7.715 46.9628 7.95718L41.955 22.1994C41.9316 22.3713 41.9863 22.4416 42.1269 22.4416H44.5566C44.705 22.4416 44.7753 22.3947 44.83 22.2462L46.166 18.2384H51.9706ZM46.9394 15.59C47.6738 13.3712 48.6425 10.5275 49.0331 8.9025H49.0566C49.541 10.6056 50.6816 13.9806 51.1972 15.59H46.9394Z"
          fill="#0D1117"
        />
        <path
          d="M63.7154 22.6837C65.1763 22.6837 66.731 22.4181 68.3091 21.7384C68.4341 21.6916 68.4576 21.6369 68.4576 21.5197C68.4107 21.0822 68.356 20.4494 68.356 19.965V5.2775C68.356 5.18375 68.356 5.13687 68.2388 5.13687H65.5904C65.4888 5.13687 65.4419 5.18375 65.4419 5.30875V10.2853C65.0279 10.2384 64.7388 10.215 64.3951 10.215C60.1216 10.215 57.4966 13.0353 57.4966 16.5587C57.4966 20.6447 60.1919 22.6837 63.7154 22.6837ZM65.4419 19.965C65.0044 20.1056 64.5201 20.1603 64.0279 20.1603C62.0904 20.1603 60.5044 19.0666 60.5044 16.4103C60.5044 14.0587 62.1372 12.6681 64.3013 12.6681C64.7388 12.6681 65.1216 12.715 65.4419 12.84V19.965Z"
          fill="#0D1117"
        />
        <path
          d="M76.9066 10.215C73.2582 10.215 71.0004 13.0119 71.0004 16.465C71.0004 19.5509 72.7972 22.6837 76.8519 22.6837C80.2816 22.6837 82.6879 20.1603 82.6879 16.3869C82.6879 13.0587 80.6488 10.215 76.9066 10.215ZM76.7582 12.6681C78.8207 12.6681 79.6957 14.4416 79.6957 16.465C79.6957 18.965 78.4066 20.2072 76.9066 20.2072C75.0551 20.2072 73.9613 18.6525 73.9613 16.4103C73.9613 14.1056 75.1254 12.6681 76.7582 12.6681Z"
          fill="#0D1117"
        />
        <path
          d="M85.426 5.13687C85.3244 5.13687 85.2541 5.18375 85.2541 5.30875V21.9337C85.2541 22.0041 85.3244 22.1291 85.426 22.1525C86.59 22.5119 87.8088 22.6837 89.0666 22.6837C92.6682 22.6837 96.1682 20.4494 96.1682 15.9494C96.1682 12.6916 93.9338 10.215 90.4338 10.215C89.6291 10.215 88.8791 10.34 88.2228 10.5587L88.1916 5.33218C88.1916 5.16031 88.1447 5.13687 87.9728 5.13687H85.426ZM93.176 16.2462C93.176 18.9416 91.3322 20.2072 89.34 20.2072C88.926 20.2072 88.5588 20.1837 88.2228 20.0822V12.9884C88.6057 12.84 89.0666 12.715 89.9182 12.715C91.84 12.715 93.176 13.9337 93.176 16.2462Z"
          fill="#0D1117"
        />
        <path
          d="M106.052 17.1212C107.24 17.1212 108.216 17.0978 108.552 17.0197C108.677 16.9962 108.724 16.9494 108.747 16.8478C108.818 16.5822 108.849 16.0275 108.849 15.3478C108.849 13.0353 107.458 10.215 103.865 10.215C100.193 10.215 98.1537 13.2072 98.1537 16.5822C98.1537 19.5744 99.7318 22.6837 104.154 22.6837C105.81 22.6837 106.88 22.4181 107.802 21.9806C107.896 21.9337 107.943 21.8556 107.943 21.715V19.6916C107.943 19.5744 107.872 19.5509 107.802 19.5978C106.88 19.9884 105.88 20.1837 104.786 20.1837C102.31 20.1837 101.185 18.8166 101.115 17.1212H106.052ZM101.115 15.0275C101.31 13.84 102.06 12.5509 103.74 12.5509C105.591 12.5509 106.076 14.1056 106.076 14.8087C106.076 14.8322 106.076 14.9337 106.076 15.0041C105.974 15.0275 105.661 15.0275 104.74 15.0275H101.115Z"
          fill="#0D1117"
        />
      </g>
      <defs>
        <clipPath id="clip0_43_158">
          <rect width="108.849" height="28.6797" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}