export default function Logo(): JSX.Element {
  return (
    <svg width="44" height="49" viewBox="0 0 44 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Hashicorp</title>
      <path d="M26.6667 36.3647L33.8667 32.3647V5.03135L26.6667 0.764679V21.1647H17.4667V13.1647L10.1333 17.1647V44.498L17.4667 48.7647V28.3647H26.6667V36.3647Z" />
      <path d="M37.3333 6.89801V34.6313L26.6667 40.498V48.7647L44 38.7647V10.7647L37.3333 6.89801ZM17.4667 0.764679L0 10.7647V38.7647L6.8 42.6313V14.898L17.4667 9.03135V0.764679Z" />
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}