export default function Logo(): JSX.Element {
  return (
    <svg width="125" height="35" viewBox="0 0 125 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Nasa</title>
      <path
        d="M22.6265 25.8549C23.0141 27.3269 23.6396 27.8961 24.745 27.8961C25.8909 27.8961 26.4918 27.2049 26.4918 25.8549V0.790224H33.67V25.8549C33.67 29.3879 33.2135 30.9187 31.4293 32.7106C30.1434 34.0019 27.7716 35 24.7767 35C22.3572 35 20.0419 34.1959 18.5654 32.7108C17.2709 31.4087 16.5598 30.0712 15.4485 25.8546L11.0438 9.14511C10.6559 7.67307 10.0307 7.10411 8.9253 7.10411C7.77911 7.10411 7.17822 7.79506 7.17822 9.14511V34.21H0V9.14511C0 5.61232 0.456756 4.08126 2.24098 2.28943C3.52639 0.998645 5.89847 0 8.89357 0C11.3131 0 13.6284 0.8043 15.1049 2.28918C16.3994 3.59132 17.1105 4.92877 18.2218 9.14536L22.6265 25.8549Z"
        fill="#0D1117"
      />
      <path
        d="M117.58 34.21L109.29 8.17289C109.199 7.88545 109.112 7.64146 108.98 7.47873C108.752 7.20042 108.437 7.05423 108.013 7.05423C107.589 7.05423 107.274 7.20042 107.046 7.47873C106.913 7.64122 106.827 7.8852 106.735 8.17289L98.4457 34.21H91.0257L100.081 5.76765C100.553 4.28598 101.227 3.02804 102.083 2.18991C103.551 0.752441 105.282 0 108.013 0C110.744 0 112.475 0.752441 113.943 2.18966C114.799 3.02804 115.473 4.28573 115.945 5.7674L125 34.2098H117.58V34.21Z"
        fill="#0D1117"
      />
      <path
        d="M80.8922 34.21C85.595 34.21 87.8889 33.2069 89.7012 31.3976C91.7065 29.3961 92.6658 27.2062 92.6658 23.9366C92.6658 21.0476 91.6123 18.3717 90.0084 16.7705C87.9113 14.6767 85.3065 14.0168 81.0592 14.0168L75.1101 14.0166C72.8369 14.0166 71.931 13.7408 71.2143 13.0338C70.7221 12.5488 70.4914 11.8274 70.4914 10.9772C70.4914 10.0983 70.7022 9.23031 71.3038 8.63739C71.8378 8.11115 72.5656 7.86964 73.9641 7.86964H91.1578V0.790224H75.2773C70.5745 0.790224 68.2809 1.79356 66.4684 3.60268C64.4633 5.60417 63.5038 7.79383 63.5038 11.0634C63.5038 13.9529 64.5572 16.6285 66.1612 18.2295C68.2585 20.3233 70.863 20.9834 75.1103 20.9834L81.0597 20.9839C83.3329 20.9839 84.2388 21.2592 84.9555 21.9665C85.4477 22.4517 85.6784 23.1728 85.6784 24.0231C85.6784 24.9019 85.4676 25.7699 84.8663 26.3629C84.332 26.8891 83.6042 27.1306 82.2057 27.1306H64.4731L57.672 5.76765C57.2002 4.28598 56.5258 3.02804 55.6701 2.18991C54.2014 0.752441 52.4708 0 49.7399 0C47.0089 0 45.2781 0.752441 43.8102 2.18966C42.9542 3.02804 42.28 4.28573 41.8083 5.7674L32.7531 34.2098H40.1728L48.4628 8.17264C48.5543 7.8852 48.6406 7.64122 48.7735 7.47848C49.0012 7.20017 49.3166 7.05398 49.7404 7.05398C50.1642 7.05398 50.4792 7.20017 50.7072 7.47848C50.8401 7.64097 50.9264 7.88495 51.0176 8.17264L59.3076 34.2098H80.8922V34.21Z"
        fill="#0D1117"
      />
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}