export default function Logo(): JSX.Element {
  return (
    <svg width="99" height="30" viewBox="0 0 99 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>FedEx</title>
      <g clipPath="url(#clip0_115_25)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.1968 11.8773V0H56.9905V29.025H50.1955V27.1459H50.1205C48.8795 28.9868 46.8491 30.0777 44.6318 30.0777C39.4923 30.0777 35.7464 25.9309 35.1627 21.1227H21.7105C21.7105 23.265 23.4968 25.0718 25.5682 25.0718C27.3545 25.0718 28.0323 24.6941 28.9255 23.4164H35.5323C33.8032 27.6641 30.5318 29.9945 25.5682 29.9945C19.6664 29.9945 15.0409 25.7455 15.0409 19.6923C15.0409 18.6791 15.1636 17.7191 15.3927 16.8232H7.70864V29.0223H0V0H17.1464V6.46909H7.70864V10.5982H16.3568V14.4014C18.0805 11.3414 21.3845 9.46364 25.5682 9.46364C30.72 9.46364 34.1945 12.1759 35.5432 16.5995C36.7241 12.585 40.0895 9.54682 44.5568 9.54682C46.7373 9.54682 48.6164 10.1482 50.1218 11.8773H50.1968ZM25.5695 13.8614C23.7845 13.8614 22.3541 15.0655 21.9259 16.9091H29.2486C28.8914 15.0286 27.5345 13.8614 25.5695 13.8614ZM42.0095 19.8327C42.0095 22.2695 43.5668 24.6041 46.0718 24.6041C48.8127 24.6041 49.9977 22.3023 49.9977 19.8327C49.9977 17.2282 48.8795 14.7232 46.0718 14.7232C43.3636 14.7232 42.0095 17.2282 42.0095 19.8327ZM81.7498 10.5981L73.1003 10.5982H63.8153V6.46909H73.1003V0H56.9916V1.25H71.8503V5.21909H62.5653V11.8482H71.8503V15.5732H62.5653V23.7855H71.8503V27.7723H56.9916V29.0223H73.1003L81.4866 29.0222L85.4726 24.5113L89.4953 29.0222H98.1803L89.9098 19.7727L98.0685 10.5981H89.7203L85.8107 15.0736L81.7498 10.5981ZM73.1003 29.0223V22.5355H63.8153V16.8232H73.1003L73.1003 10.5982L81.2985 19.8109L73.1003 29.0223ZM80.9231 27.7722H75.8862L82.9718 19.8109L75.8859 11.8481H81.1962L85.8285 16.9533L90.2881 11.8481H95.2841L88.235 19.775L95.3858 27.7722H90.0554L85.4683 22.6284L80.9231 27.7722Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_115_25">
          <rect width="98.1818" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}