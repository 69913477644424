export default function Logo(): JSX.Element {
  return (
    <svg width="205" height="41" viewBox="0 0 205 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Société Générale</title>
      <g clipPath="url(#clip0_2_693)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.3751 0.764679H0V41.1398H40.3751V0.764679ZM33.3548 19.8511H7.34093V22.2546H33.3548V19.8511Z"
        />
        <path d="M66.731 5.28883C64.6103 4.44055 62.2069 3.73365 60.2276 3.73365C57.2586 3.73365 55.7034 4.44053 55.7034 5.57156C55.7034 8.82328 69.1345 6.27846 69.1345 13.3474C69.1345 16.3164 66.4483 18.5785 60.9345 18.5785C57.6828 18.5785 55.1379 17.8716 52.1689 16.3164L53.4414 13.6302C55.9862 15.044 58.3897 15.7509 60.9345 15.7509C64.1862 15.7509 65.8828 14.7612 65.8828 13.3474C65.8828 9.67156 52.4517 12.4992 52.4517 5.85434C52.4517 3.02675 55.1379 1.04745 60.0862 1.04745C63.0552 1.04745 65.4587 1.75433 67.8621 2.88536L66.731 5.28883Z" />
        <path d="M81.7172 18.5785C76.3448 18.5785 71.962 15.044 71.962 9.67156C71.962 4.44052 76.3448 0.764679 81.7172 0.764679C87.0897 0.764679 91.4724 4.44052 91.4724 9.67156C91.331 15.044 87.0897 18.5785 81.7172 18.5785ZM81.7172 3.59227C77.7586 3.59227 75.2137 6.27846 75.2137 9.67156C75.2137 13.0647 77.7586 15.7509 81.7172 15.7509C85.6759 15.7509 88.2207 13.206 88.2207 9.67156C88.2207 6.27846 85.6759 3.59227 81.7172 3.59227Z" />
        <path d="M104.196 18.5785C98.5413 18.5785 94.2999 15.044 94.2999 9.67156C94.2999 4.44052 98.5413 0.764679 104.196 0.764679C107.448 0.764679 109.852 1.75432 111.972 3.30949L110.276 5.71295C108.721 4.58192 106.741 3.59227 104.196 3.59227C100.096 3.59227 97.5517 6.13711 97.5517 9.53021C97.5517 12.9233 100.096 15.4681 104.196 15.4681C106.741 15.4681 108.862 14.6199 110.276 13.4888L111.972 15.8923C109.993 17.5888 107.448 18.5785 104.196 18.5785Z" />
        <path d="M115.507 1.33017H118.617V18.1543H115.507V1.33017Z" />
        <path d="M123.141 18.013V1.33017H138.41V4.01637H126.252V8.11641H135.724V10.944H126.252V15.3267H138.976V18.013H123.141Z" />
        <path d="M140.672 4.01637V1.33017H157.497V4.01637H150.569V18.013H147.459V4.01637H140.672Z" fill="#24292F" />
        <path d="M159.9 18.013V1.33017H175.169V4.01637H163.01V8.11641H172.483V10.944H163.01V15.3267H175.735V18.013H159.9Z" />
        <path d="M61.7828 37.9474C63.4793 37.9474 65.1758 37.5233 66.5896 36.9578V32.1509H69.5586V38.6543C67.4379 40.0681 64.469 40.9164 61.6414 40.9164C55.9862 40.9164 51.7448 37.3819 51.7448 32.0095C51.7448 26.7785 55.9862 23.1026 61.6414 23.1026C64.8931 23.1026 67.2965 23.9509 69.4172 25.6475L67.7207 28.0509C66.1655 26.9198 64.1862 26.0716 61.6414 26.0716C57.5414 25.9302 54.9965 28.6164 54.9965 32.0095C54.9965 35.4026 57.5414 37.9474 61.7828 37.9474Z" />
        <path d="M73.6586 40.3509V23.6681H88.9275V26.3543H76.769V30.4543H86.2414V33.2819H76.769V37.6647H89.4931V40.3509H73.6586Z" />
        <path d="M95.5724 40.3509H92.462V23.5268H95.5724L106.459 36.1095H106.6C106.459 32.9992 106.459 30.313 106.459 27.9095V23.5268H109.428V40.3509H106.317L95.4311 27.7682C95.5724 30.313 95.5724 34.5543 95.5724 35.9681V40.3509Z" />
        <path d="M113.81 40.3509V23.6681H129.079V26.3543H116.921V30.4543H126.393V33.2819H116.921V37.6647H129.645V40.3509H113.81Z" />
        <path d="M141.097 34.2716H136.148V40.4923H133.038V23.6681H143.783C147.317 23.6681 149.579 25.9302 149.579 29.0405C149.579 32.0095 147.459 33.8474 144.772 34.2716L149.721 40.4923H145.762L141.097 34.2716ZM143.359 31.444C145.338 31.444 146.469 30.4543 146.469 29.0405C146.469 27.4854 145.479 26.4957 143.783 26.4957H136.148V31.5853H143.359V31.444Z" />
        <path d="M156.083 36.3923L154.103 40.4923H150.71L158.91 23.6681H162.162L170.362 40.4923H166.969L164.99 36.3923H156.083ZM157.355 33.5647H163.859L160.607 26.7785L157.355 33.5647Z" />
        <path d="M172.766 40.3509V23.6681H175.876V37.6647H187.045V40.3509H172.766Z" fill="#24292F" />
        <path d="M189.166 40.3509V23.6681H204.435V26.3543H192.276V30.4543H201.748V33.2819H192.276V37.6647H205V40.3509H189.166Z" />
      </g>
      <defs>
        <clipPath id="clip0_2_693">
          <rect width="205" height="40.1517" fill="white" transform="translate(0 0.764679)" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}