export default function Logo(): JSX.Element {
  return (
    <svg width="212" height="40" viewBox="0 0 212 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Philips</title>
      <g clipPath="url(#clip0_1_672)">
        <path d="M22.0696 15.0456C22.0696 19.8592 19.7416 23.1194 13.8461 23.1194H11.9832V7.78916C12.6454 7.75172 13.2639 7.75172 13.8461 7.75172C19.4284 7.75119 22.0696 10.3897 22.0696 15.0456ZM16.018 29.7943C26.5668 29.7943 33.2364 24.3596 33.2364 14.8922C33.2359 4.48941 26.1007 0.764679 14.4667 0.764679C9.96736 0.764679 4.53792 1.07419 0.660797 1.53979V38.6448H11.9837V29.7943H16.018ZM167.727 15.0456C167.727 19.8592 165.402 23.1194 159.505 23.1194H157.649V7.78916C158.305 7.75172 158.925 7.75172 159.505 7.75172C165.088 7.75119 167.727 10.3897 167.727 15.0456ZM161.682 29.7943C172.223 29.7943 178.896 24.3596 178.896 14.8922C178.896 4.48941 171.762 0.764679 160.127 0.764679C155.629 0.764679 150.196 1.07419 146.318 1.53979V38.6448H157.647V29.7943H161.682ZM193.63 11.0093C193.63 8.99189 195.649 8.05913 198.904 8.05913C202.473 8.05913 206.354 8.83582 208.989 9.92677L208.059 1.85141C205.11 1.23027 202.473 0.764679 197.666 0.764679C188.513 0.764679 182.31 4.37552 182.31 11.9436C182.31 24.9807 199.996 22.3438 199.996 28.3981C199.996 30.7244 198.287 31.9657 194.097 31.9657C190.38 31.9657 184.636 30.7244 181.224 29.0176L182.468 37.5586C185.878 38.8003 190.532 39.4193 194.256 39.4193C203.715 39.4193 211.473 36.3136 211.473 26.9976C211.473 14.5827 193.63 16.9091 193.63 11.0093ZM137.79 1.53979H126.467V38.6448H137.79V1.53979ZM91.8725 1.53979H80.5486V38.6448H91.8725V1.53979ZM111.573 31.1906V1.53979H100.249V38.6448H120.727L122.432 31.1906H111.573ZM72.1727 1.53979H60.8487V16.2879H49.0592V1.53979H37.7331V38.6448H49.0586V24.0501H60.8482V38.6448H72.1721L72.1727 1.53979Z" />
      </g>
      <defs>
        <clipPath id="clip0_1_672">
          <rect width="211" height="38.6552" fill="white" transform="translate(0.567261 0.764679)" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}