export default function Logo(): JSX.Element {
  return (
    <svg width="53" height="55" viewBox="0 0 53 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Ernst and Young</title>
      <g clipPath="url(#clip0_1_688)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.55934 48.1536H21.9656V54.2663H0.517113V27.7007H15.7217L19.2797 33.8134H8.56706V38.1973H18.261V43.7698H8.56706L8.55934 48.1536ZM36.2364 27.7007L31.6827 36.3681L27.1445 27.7007H18.2532L27.6153 43.7698V54.2663H35.6421V43.7698L45.0041 27.7007H36.2364Z"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M53 10.6515V0.764679L0 19.9287L53 10.6515Z" />
      </g>
      <defs>
        <clipPath id="clip0_1_688">
          <rect width="53" height="53.5017" fill="white" transform="translate(0 0.764679)" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}