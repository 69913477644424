export default function Logo(): JSX.Element {
  return (
    <svg width="172" height="39" viewBox="0 0 172 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Vercel</title>
      <g clipPath="url(#clip0_69_91)">
        <path
          d="M85.8062 9.75002C79.12 9.75002 74.2992 14.07 74.2992 20.55C74.2992 27.03 79.7256 31.35 86.4118 31.35C90.4514 31.35 94.0125 29.766 96.217 27.096L91.5839 24.444C90.3606 25.77 88.5013 26.544 86.4118 26.544C83.5108 26.544 81.0459 25.044 80.1314 22.644H97.1013C97.2345 21.972 97.3132 21.276 97.3132 20.544C97.3132 14.07 92.4924 9.75002 85.8062 9.75002ZM80.0769 18.45C80.8339 16.056 82.9052 14.55 85.8001 14.55C88.7011 14.55 90.7724 16.056 91.5234 18.45H80.0769ZM151.021 9.75002C144.335 9.75002 139.514 14.07 139.514 20.55C139.514 27.03 144.94 31.35 151.626 31.35C155.666 31.35 159.227 29.766 161.432 27.096L156.799 24.444C155.575 25.77 153.716 26.544 151.626 26.544C148.725 26.544 146.261 25.044 145.346 22.644H162.316C162.449 21.972 162.528 21.276 162.528 20.544C162.528 14.07 157.707 9.75002 151.021 9.75002ZM145.298 18.45C146.055 16.056 148.126 14.55 151.021 14.55C153.922 14.55 155.993 16.056 156.744 18.45H145.298ZM121.66 20.55C121.66 24.15 124.034 26.55 127.716 26.55C130.211 26.55 132.083 25.428 133.046 23.598L137.697 26.256C135.771 29.436 132.161 31.35 127.716 31.35C121.024 31.35 116.209 27.03 116.209 20.55C116.209 14.07 121.03 9.75002 127.716 9.75002C132.161 9.75002 135.765 11.664 137.697 14.844L133.046 17.502C132.083 15.672 130.211 14.55 127.716 14.55C124.04 14.55 121.66 16.95 121.66 20.55ZM171.612 3.15002V30.75H166.162V3.15002H171.612ZM22.7658 0.150024L45.1439 38.55H0.387604L22.7658 0.150024ZM78.7142 3.15002L61.9321 31.95L45.15 3.15002H51.4425L61.9321 21.15L72.4217 3.15002H78.7142ZM114.392 10.35V16.164C113.786 15.99 113.145 15.87 112.454 15.87C108.935 15.87 106.398 18.27 106.398 21.87V30.75H100.947V10.35H106.398V15.87C106.398 12.822 109.977 10.35 114.392 10.35Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_69_91">
          <rect width="172" height="39" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}