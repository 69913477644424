export default function Logo(): JSX.Element {
  return (
    <svg width="126" height="48" viewBox="0 0 126 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Plaid</title>
      <g clipPath="url(#clip0_43_151)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.248 16.268C65.191 15.379 63.387 14.935 60.835 14.935H55.079V32.723H59.383V27.148H61.311C63.651 27.148 65.367 26.633 66.459 25.602C67.689 24.447 68.308 22.909 68.308 20.989C68.308 18.998 67.621 17.424 66.248 16.268ZM61.204 23.123H59.383V18.96H61.019C63.009 18.96 64.004 19.658 64.004 21.054C64.004 22.432 63.07 23.123 61.204 23.123ZM75.673 14.934H71.185V32.722H80.875V28.696H75.673V14.934ZM89.668 14.934L82.618 32.722H87.45L88.374 30.136H94.5L95.345 32.722H100.231L93.231 14.934H89.668ZM89.615 26.535L91.464 20.455L93.284 26.535H89.615Z"
        />
        <g mask="url(#mask0_43_151)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M102.473 32.722H106.962V14.934H102.473V32.722ZM124.39 18.268C123.823 17.4458 123.095 16.7474 122.25 16.215C120.895 15.361 119.046 14.935 116.705 14.935H110.791V32.722H117.709C120.209 32.722 122.215 31.905 123.729 30.269C125.243 28.634 125.999 26.464 125.999 23.761C125.999 21.611 125.463 19.78 124.39 18.268ZM117.207 28.695H115.28V18.961H117.234C118.607 18.961 119.662 19.391 120.402 20.248C121.142 21.105 121.512 22.321 121.512 23.895C121.512 27.095 120.077 28.695 117.207 28.695ZM18.637 0L4.09 3.81L0.081 18.439L5.095 23.587L0 28.65L3.773 43.343L18.257 47.39L23.353 42.326L28.367 47.473L42.914 43.663L46.922 29.033L41.909 23.887L47.004 18.824L43.231 4.13L28.745 0.083L23.651 5.146L18.637 0ZM9.71 6.624L17.373 4.616L20.724 8.056L15.837 12.912L9.71 6.624ZM26.532 8.102L29.937 4.719L37.567 6.851L31.34 13.038L26.532 8.102ZM4.672 17.238L6.783 9.533L12.908 15.821L8.022 20.677L4.672 17.237V17.238ZM34.219 15.995L40.446 9.806L42.432 17.546L39.028 20.93L34.219 15.995ZM18.717 15.868L23.604 11.012L28.411 15.948L23.525 20.804L18.717 15.868ZM10.903 23.633L15.789 18.777L20.599 23.713L15.711 28.569L10.903 23.633ZM26.406 23.76L31.292 18.904L36.1 23.84L31.213 28.696L26.406 23.76ZM4.57 29.927L7.976 26.542L12.783 31.479L6.558 37.665L4.57 29.927ZM18.591 31.525L23.478 26.669L28.286 31.605L23.4 36.461L18.591 31.525ZM34.093 31.653L38.98 26.797L42.331 30.236L40.221 37.941L34.093 31.653ZM9.437 40.623L15.663 34.434L20.473 39.37L17.067 42.755L9.437 40.623ZM26.28 39.417L31.166 34.561L37.292 40.85L29.63 42.857L26.28 39.417Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_43_151">
          <rect width="126" height="48" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}