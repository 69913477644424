export default function Logo(): JSX.Element {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>HP</title>
      <g clipPath="url(#clip0_99_42)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.5586 0.048027C37.7874 0.048027 48.5106 10.7712 48.5106 24C48.5106 37.228 37.7874 47.952 24.5586 47.952C24.3208 47.952 24.0831 47.9475 23.8472 47.9411L28.742 34.4882H35.4785C36.6607 34.4882 37.9586 33.5792 38.3631 32.468L43.6732 17.8811C44.5421 15.4938 43.1749 13.5392 40.6337 13.5392H31.2878L23.4464 35.0866H23.4428L18.998 47.3017C8.45151 44.7942 0.606567 35.3134 0.606567 24C0.606567 13.0127 8.00521 3.75418 18.0917 0.932442L13.5039 13.5392L5.87843 34.4882H10.9545L17.4396 16.687H21.255L14.7699 34.4882L19.8441 34.4891L25.8875 17.8811C26.7564 15.4938 25.3883 13.5392 22.8499 13.5392H18.5826L23.482 0.0735321C23.839 0.0571365 24.1979 0.048027 24.5586 0.048027ZM35.2381 16.6741H39.0517L33.7106 31.3284H29.897L35.2381 16.6741Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_99_42">
          <rect width="48" height="48" fill="white" transform="translate(0.558655)" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}