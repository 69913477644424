export default function Logo(): JSX.Element {
  return (
    <svg width="199" height="26" viewBox="0 0 199 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Dow Jones</title>
      <g clipPath="url(#clip0_43_206)">
        <path
          d="M18.3024 8.67711C18.2694 8.14458 18.2363 7.89398 18.1372 7.5494C17.5756 5.70121 15.7586 4.35422 13.6442 4.35422H4.59212V21.7084H9.18424V26H0V0H13.6442C18.2694 0 22.0686 3.19518 22.7294 7.39277C22.7954 7.8 22.8285 8.20723 22.8285 8.67711H18.3024Z"
          fill="#0D1117"
        />
        <path
          d="M22.862 13V17.2916C22.862 22.0216 18.7111 25.8973 13.7438 25.998V21.6771H13.4795C16.0564 21.6771 18.2698 19.6723 18.2698 17.2289V13H22.862Z"
          fill="#0D1117"
        />
        <path d="M13.7438 25.998V26H13.5456C13.6118 26 13.6779 25.9993 13.7438 25.998Z" fill="#0D1117" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.5884 21.7468C71.0623 21.7468 68.4855 19.2694 68.4855 15.617V10.8212C68.4855 7.13698 71.0623 4.65967 75.5884 4.65967C80.1144 4.65967 82.6913 7.13698 82.6913 10.8212V15.617C82.6583 19.3012 80.0814 21.7468 75.5884 21.7468ZM79.0573 10.8212C79.0573 8.8838 77.5376 7.83571 75.5554 7.83571C73.6062 7.83571 72.0535 8.91556 72.0535 10.8212V15.617C72.0535 17.5226 73.5731 18.6025 75.5554 18.6025C77.5045 18.6025 79.0573 17.5226 79.0573 15.617V10.8212Z"
          fill="#0D1117"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.2824 21.5243V4.91366H57.3853C61.9114 4.91366 64.4882 7.39096 64.4882 11.0434V15.3946C64.4882 19.0788 61.9114 21.5243 57.3853 21.5243H50.2824ZM60.8542 11.0752C60.8542 9.16954 59.3345 8.08969 57.3523 8.08969H53.8504V18.3801H57.3523C59.3015 18.3801 60.8542 17.3002 60.8542 15.3946V11.0752Z"
          fill="#0D1117"
        />
        <path
          d="M93.3952 21.5243L96.4346 12.2185L99.507 21.5243H103.372L106.841 4.91366H103.24L101.06 16.1568L97.5579 4.91366H95.3444L91.8425 16.1568L89.6621 4.91366H86.0611L89.4969 21.5243H93.3952Z"
          fill="#0D1117"
        />
        <path
          d="M124.285 4.91366H127.886V16.7603C127.886 20.0633 125.804 21.7467 122.303 21.7467C118.801 21.7467 116.719 20.0633 116.719 16.7603H120.353C120.453 17.9354 121.146 18.5706 122.303 18.5706C123.69 18.5706 124.285 17.7448 124.285 16.665V4.91366Z"
          fill="#0D1117"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M132.246 15.617C132.246 19.2694 134.823 21.7468 139.349 21.7468C143.875 21.7468 146.452 19.3012 146.452 15.617V10.8212C146.452 7.13698 143.875 4.65967 139.349 4.65967C134.823 4.65967 132.246 7.13698 132.246 10.8212V15.617ZM139.349 7.83571C141.332 7.83571 142.851 8.8838 142.851 10.8212V15.617C142.851 17.5226 141.298 18.6025 139.349 18.6025C137.367 18.6025 135.847 17.5226 135.847 15.617V10.8212C135.847 8.91556 137.4 7.83571 139.349 7.83571Z"
          fill="#0D1117"
        />
        <path
          d="M165.019 21.5243H161.418L154.447 10.8211V21.5243H150.846V4.91366H154.447L161.418 15.6169V4.91366H165.019V21.5243Z"
          fill="#0D1117"
        />
        <path
          d="M180.381 11.5516H173.377V8.08969H181.207V4.91366H169.776V21.5243H181.207V18.3801H173.377V14.7276H180.381V11.5516Z"
          fill="#0D1117"
        />
        <path
          d="M185.006 16.284H188.706C188.904 17.6814 190.16 18.6025 191.944 18.6025C193.529 18.6025 194.62 18.0943 194.62 16.9509C194.62 16.0299 193.992 15.49 192.671 15.2041L189.499 14.4419C186.625 13.7432 185.204 11.9646 185.204 9.51902C185.204 6.18418 188.144 4.69144 191.448 4.69144C195.214 4.69144 197.527 6.62882 197.956 9.4555H194.256C193.926 8.34388 192.604 7.83573 191.415 7.83573C189.895 7.83573 188.838 8.34389 188.838 9.51902C188.838 10.5036 189.697 10.9165 190.721 11.1706L193.794 11.9011C196.701 12.5998 198.254 14.156 198.254 16.9509C198.254 19.9364 196.04 21.7785 191.977 21.7785C187.847 21.7468 185.171 19.5871 185.006 16.284Z"
          fill="#0D1117"
        />
      </g>
      <defs>
        <clipPath id="clip0_43_206">
          <rect width="198.254" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}